//librerías
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faRepeat,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  Row,
  Spinner,
} from "react-bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Modal } from "react-bootstrap";
import { PlataformasxUsuarioInterface } from "../../interfaces/PlataformasxUsuario";
import useFetch from "../../hooks/useFetch";
import { toast } from "react-toastify";

//estilos
const classes = require("./Main.module.css").default;

interface Props {
  showModal: boolean;
  handleCloseModal: () => void;
  titulo: string;
}

const CustomModal = ({ showModal, handleCloseModal, titulo }: Props) => {
  const authContext = useContext(AuthContext);
  const [plataformas, setPlataformas] =
    useState<PlataformasxUsuarioInterface[]>();
  const { sendRequest } = useFetch();
  const [plataformasAgregadas, setPlataformasAgregadas] = useState<
    PlataformasxUsuarioInterface[]
  >([]);
  const navigate = useNavigate();

  const saveCurrentPlatforms = () => {
    if (plataformasAgregadas.length === 0) {
      toast.error("Se debe seleccionar al menos una plataforma", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    sendRequest(
      {
        url: `/updateplataformas_usuario/${authContext.usuario!.id}`,
        method: "POST",
        body: { current_platforms: plataformasAgregadas },
      },
      () => {
        authContext.updatePlatformas(plataformasAgregadas);
        toast.success("Se actualizaron las plataformas", {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        navigate(`/main`);
        handleCloseModal();
      }
    );
  };

  //Función que trata los prueba traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setPlataformas(variable.data);
  }, []);

  //Función que trae las plataformas por usuario
  useEffect(() => {
    sendRequest(
      {
        url: "/plataformas_usuario",
        method: "POST",
        body: { usuario_id: authContext.usuario!.id },
      },
      transformData
    );
  }, [sendRequest, transformData, authContext]);

  //Mientras se carga el contenido se muestra un spinner
  if (!plataformas) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{titulo}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row xs={1} md={2} className="g-4">
          {plataformas.map(
            (plataforma, idx) =>
              plataforma.pivot && (
                <Col key={idx}>
                  <Card
                    className={classes.fadeCard}
                    onClick={() => {
                      if (
                        plataformasAgregadas.find(
                          (element) => element.id === plataforma.id
                        )
                      ) {
                        setPlataformasAgregadas((prev) =>
                          prev.filter((e) => e.id !== plataforma.id)
                        );
                      } else {
                        setPlataformasAgregadas((prev) => [
                          ...prev,
                          plataforma,
                        ]);
                      }
                    }}
                  >
                    <Card.Img
                      style={{
                        width: "180px",
                        height: "130px",
                        paddingLeft: "30px",
                        paddingTop: "10px",
                      }}
                      src={require(`../../img/plataformas/${plataforma.id}.png`)}
                    />
                    <Card.Body></Card.Body>
                    <Card.Footer className="text-muted">
                      <Form.Check
                        style={{ color: "black", fontSize: 13 }}
                        label={plataforma.nombre}
                        aria-label="option 1"
                        readOnly
                        checked={
                          plataformasAgregadas.find(
                            (element) => element.id === plataforma.id
                          )
                            ? true
                            : false
                        }
                      />
                    </Card.Footer>
                  </Card>
                </Col>
              )
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={saveCurrentPlatforms}>
          Actualizar plataformas
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

/**
 * Screen Main
 * @description: Es la pantalla que contiene el nav de la aplicación.
 * @date 14/11/2022.
 * @returns JSX del Screen.
 */

const Main = () => {
  const authContext = useContext(AuthContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      {/**Navbar */}
      <Navbar
        bg="light"
        variant="light"
        style={{ position: "fixed", top: 0, width: "100%", zIndex: 2 }}
      >
        <Container>
          <Navbar.Brand href="/main">
            <img
              src={require("../../img/skyangel.png")}
              alt="Mountain"
              width={"45"}
              height={"40"}
            />
            SkyTech
          </Navbar.Brand>
          <Nav className="me-auto">
            <Link to="" className={classes.navlink}>
              Dashboard
            </Link>
            {authContext.tokenCan("Web:view") && (
              <NavDropdown
                style={{ marginRight: "20px" }}
                title="Servicios"
                menuVariant="dark"
              >
                <Link
                  className={classes.dropdownItem}
                  to="servicios/capturados"
                >
                  Capturados
                </Link>
                <Link
                  className={classes.dropdownItem}
                  to="servicios/programados"
                >
                  Programados
                </Link>
                <Link
                  className={classes.dropdownItem}
                  to="servicios/por_firmar"
                >
                  Por firmar
                </Link>
                <Link className={classes.dropdownItem} to="servicios/atendidos">
                  Atendidos
                </Link>
                <Link className={classes.dropdownItem} to="servicios/en_falso">
                  Salidas en Falso
                </Link>
              </NavDropdown>
            )}
            {authContext.tokenCan("Usuario:show") && (
              <Link className={classes.navlink} to="catalogos">
                Catálogos
              </Link>
            )}
            <NavDropdown title="Administración" menuVariant="dark">
              {authContext.tokenCan("Cobro:store") && (
                <Link className={classes.dropdownItem} to="servicios/cobrar">
                  Cobros
                </Link>
              )}
              {authContext.tokenCan("Cobro:store") && (
                <Link className={classes.dropdownItem} to="servicios/cobrados">
                  Servicios cobrados
                </Link>
              )}
              {authContext.tokenCan("Reportes:download") && (
                <Link className={classes.dropdownItem} to="reportes">
                  Reportes
                </Link>
              )}
              {authContext.tokenCan("Pago:store") && (
                <Link className={classes.dropdownItem} to="servicios/pagar">
                  Pagar a Proveedores
                </Link>
              )}
              {authContext.tokenCan("Pago:store") && (
                <Link className={classes.dropdownItem} to="servicios/pagados">
                  Proveedores con pago
                </Link>
              )}
            </NavDropdown>
          </Nav>
          <ButtonGroup className="mb-2">
            <Button
              variant="outline-primary"
              style={{ float: "right" }}
              title="Búsqueda General"
            >
              <Link className={classes.linkInButton} to="busqueda">
                <FontAwesomeIcon icon={faMagnifyingGlass} /> Búsqueda
              </Link>
            </Button>
            <Button
              variant="outline-dark"
              style={{ float: "right" }}
              title="Cambiar Plataforma"
              onClick={() => setShowModal(true)}
            >
              <FontAwesomeIcon icon={faRepeat} />
            </Button>
            <Button
              variant="outline-dark"
              style={{ float: "right" }}
              onClick={authContext.logout}
              title="Cerrar sesión"
            >
              <FontAwesomeIcon icon={faRightFromBracket} /> Cerrar sesión
            </Button>
          </ButtonGroup>
        </Container>
      </Navbar>

      <Container
        style={{
          marginTop: "70px",
          textAlign: "left",
        }}
      >
        {authContext.plataformas.map((plataforma) => (
          <Badge
            key={`Plataforma${plataforma.id}`}
            bg="dark"
            style={{ marginRight: "10px" }}
          >
            {plataforma.nombre}
          </Badge>
        ))}
      </Container>
      <Outlet />
      {/** Modal de cobro */}
      {showModal && (
        <CustomModal
          titulo="Seleccionar plataformas"
          showModal={showModal}
          handleCloseModal={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default Main;
