import { useCallback, useContext } from "react";
import { API } from "../global/global";
import { AuthContext } from "../context/AuthContext";

const useDownloadPDF = (
  loadingFunction: (value: React.SetStateAction<boolean>) => void | null
) => {
  const authContext = useContext(AuthContext);

  //Función que muestra el PDF del servicio en otra pestaña.
  const mostrarPDF = useCallback(
    async (idActividad: number) => {
      loadingFunction(true);
      const response = await fetch(`${API}/downloadPDF/${idActividad}`, {
        method: "get",
        headers: {
          Authorization: "Bearer " + authContext.token,
        },
      });

      if (!response.ok) {
        if (response.status === 500) throw new Error("Request failed!");
        loadingFunction(false);
        return;
      }

      const data = await response.blob();

      //se crea el link con la descarga.
      let url = window.URL.createObjectURL(data);
      window.open(url);
      loadingFunction(false);
    },
    [authContext.token, loadingFunction]
  );

  return { mostrarPDF };
};

export default useDownloadPDF;
