import { Button, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { ServicioCobrado } from "../../../types/ServicioCobrado";
import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";

interface Props {
  servicio: ServicioCobrado | undefined;
  handleCloseModal: () => void;
  getCobros: () => void;
}

interface CobrosForm {
  costo_servicio: number | undefined;
  costo_equipo: number | undefined;
  costo_traslado: number | undefined;
}

const TicketCobroModal = ({ servicio, handleCloseModal, getCobros }: Props) => {
  const { sendRequest } = useFetch();
  const [cobros, setCobros] = useState<CobrosForm>({
    costo_servicio: servicio?.cobro.costo_servicio,
    costo_equipo: servicio?.cobro.costo_equipo,
    costo_traslado: servicio?.cobro.costo_traslado,
  });

  //Función que trata los servicios traidos de la BD.
  const transformData = useCallback(
    (variable: any) => {
      handleCloseModal();
      toast.success("Se ha actualizado el cobro.", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getCobros();
    },
    [handleCloseModal, getCobros]
  );

  const saveCambios = (): void => {
    sendRequest(
      {
        url: `/cobro/${servicio?.cobro.id}`,
        method: "PATCH",
        body: {
          ...cobros,
        },
      },
      transformData
    );
  };

  return (
    <Modal show={!!servicio} onHide={handleCloseModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Ticket de este servicio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {servicio?.cobro.tipo_cobro_id === 1 && (
          <Table responsive>
            <thead>
              <tr>
                <th>Costo del servicio</th>
                <th>Costo del equipo</th>
                <th>Costo del traslado</th>
                <th>Observaciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                    <Form.Control
                      defaultValue={servicio.cobro.costo_servicio}
                      onChange={(e) =>
                        setCobros((prev: any) => {
                          return { ...prev, costo_servicio: e.target.value };
                        })
                      }
                    />
                  </InputGroup>
                </td>
                <td>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                    <Form.Control
                      defaultValue={servicio.cobro.costo_equipo}
                      onChange={(e) =>
                        setCobros((prev: any) => {
                          return { ...prev, costo_equipo: e.target.value };
                        })
                      }
                    />
                  </InputGroup>
                </td>
                <td>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                    <Form.Control
                      defaultValue={servicio.cobro.costo_traslado}
                      onChange={(e) =>
                        setCobros((prev: any) => {
                          return { ...prev, costo_traslado: e.target.value };
                        })
                      }
                    />
                  </InputGroup>
                </td>
                <td>{servicio?.cobro.observaciones}</td>
              </tr>
            </tbody>
          </Table>
        )}
        {servicio?.cobro.tipo_cobro_id && servicio?.cobro.tipo_cobro_id > 1 && (
          <p>
            Este servicio no tiene cobro:{" "}
            <strong>{servicio?.cobro.tipo_cobro.nombre}</strong>
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          style={{ float: "right" }}
          onClick={handleCloseModal}
        >
          Cerrar
        </Button>
        <Button
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
          }}
          onClick={saveCambios}
        >
          Guardar cambios
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TicketCobroModal;
