import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API } from "../global/global";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

export const useGetData = (url: string, name: string) => {
  const authContext = useContext(AuthContext);

  const getData = async () => {
    const { data } = await axios.get(API + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authContext.token,
      },
    });
    return data["data"];
  };

  const { data, isLoading, refetch } = useQuery([name], getData);

  return {
    data,
    isLoading,
    refetch,
  };
};
