import {
  Alert,
  Button,
  Col,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { UnidadInterface } from "../../../interfaces/UnidadInterface";
import { Dispatch, SetStateAction, useState } from "react";
import Select from "react-select";
import { ClientesInterface } from "../../../interfaces/ClientesInterface";
import { toast } from "react-toastify";
import useFetch from "../../../hooks/useFetch";
import ServerMessage from "../../Mensajes/ServerMessage";

interface Props {
  unidad: UnidadInterface | undefined;
  resetUnit: Dispatch<SetStateAction<UnidadInterface | undefined>>;
  clientes: ClientesInterface[] | undefined;
  transformData: (variable: any) => void;
  idCliente: string | undefined;
}

/**
 * CobroModal Component
 * @description: Permite establecer que un servicio se va a cobrar y el total del mismo.
 * @date 06/03/2023.
 * @param Props actividadId que es el id de la actividad, showModal que es una variable de estado
 * que define si se muestra el modal o no, handleCloseModal es la funcion que cierra el modal.
 * @returns JSX del Modal.
 */

const ChangeUnitModal = ({
  unidad,
  resetUnit,
  clientes,
  transformData,
  idCliente,
}: Props) => {
  const [clienteNuevo, setClienteNuevo] = useState<number>();
  const { sendRequest, serverMessage, setServerMessage } = useFetch();

  const changefromClienteUnidad = (): void => {
    if (clienteNuevo === unidad?.cliente.id) {
      toast.error(
        `Se debe asignar un cliente nuevo. La unidad ya pertenece a ${unidad?.cliente.razon_social}`,
        {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      return;
    }
    if (!clienteNuevo) {
      toast.error(`Es necesario seleccionar un cliente.`, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (
      window.confirm(
        "¿Seguro que desea cambiar cambiar la unidad de cliente? ESTA ACCIÓN AFECTA A TODAS LAS ÓRDENES DE SERVICIO ASOCIADAS A ESTA UNIDAD"
      )
    ) {
      sendRequest(
        {
          method: "POST",
          url: `/migrate_unidad/${unidad!.id}`,
          body: {
            cliente_id: clienteNuevo,
          },
        },
        () => {
          toast.success(`Se actualizó el cliente de esta unidad.`, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          sendRequest(
            {
              url: `/unidad?includeMarcaUnidad=true&includeCliente=true&cliente_id[eq]=${idCliente}`,
            },
            transformData
          );
          resetUnit(undefined);
        }
      );
    }
  };

  const reset = () => {
    resetUnit(undefined);
    setServerMessage(undefined);
  };

  return (
    <Modal show={!!unidad} onHide={reset}>
      <Modal.Header closeButton>
        <Modal.Title>Migración de la unidad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/** Error del servidor */}
        {serverMessage && (
          <ServerMessage
            message={serverMessage}
            onCloseHandler={() => setServerMessage(undefined)}
          />
        )}
        <Alert variant="secondary">
          <ListGroup>
            <ListGroup.Item action variant="secondary">
              Submarca: {unidad?.submarca}
            </ListGroup.Item>
            <ListGroup.Item action variant="secondary">
              Modelo: {unidad?.modelo}
            </ListGroup.Item>
            <ListGroup.Item action variant="secondary">
              Económico: {unidad?.economico}
            </ListGroup.Item>
            <ListGroup.Item action variant="secondary">
              VIN: {unidad?.vin}
            </ListGroup.Item>
            <ListGroup.Item action variant="secondary">
              Placa: {unidad?.placa}
            </ListGroup.Item>
            <ListGroup.Item action variant="secondary">
              Color: {unidad?.color}
            </ListGroup.Item>
            <ListGroup.Item action variant="secondary">
              Voltaje: {unidad?.voltaje}
            </ListGroup.Item>
          </ListGroup>
          <hr />
          <h5 className="text-center">Cliente actual</h5>
          <Form.Label>Razón social</Form.Label>
          <Form.Select aria-label="Default select example" disabled>
            <option>{unidad?.cliente.razon_social}</option>
          </Form.Select>
          <Form.Label>Nombre comercial</Form.Label>
          <Form.Select aria-label="Default select example" disabled>
            <option>{unidad?.cliente.nombre_comercial}</option>
          </Form.Select>
          <h5 className="text-center">Seleccionar cliente nuevo</h5>
          <Form.Group as={Col}>
            <Form.Label>Razón social</Form.Label>
            <Select
              onChange={(value: any) => {
                if (value) {
                  setClienteNuevo(value.value);
                } else {
                  setClienteNuevo(undefined);
                }
              }}
              placeholder="Selecciona una opción"
              isClearable
              options={clientes?.map((cliente: ClientesInterface) => {
                return {
                  value: cliente.id,
                  label: cliente.razon_social,
                };
              })}
            />
          </Form.Group>
        </Alert>
        <Row className="mb-3">
          <Col>
            <Button
              variant="secondary"
              style={{ float: "right" }}
              onClick={reset}
            >
              Cancelar
            </Button>
          </Col>
          <Col>
            <Button
              style={{
                backgroundColor: "#21618C",
                borderColor: "white",
              }}
              onClick={changefromClienteUnidad}
            >
              Confirmar
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeUnitModal;
