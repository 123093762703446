import { Modal } from "react-bootstrap";
import { Etapa } from "../../../interfaces/ActividadBusqueda";
import TimelineCard from "../../TimelineCard/TimelineCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCheckSquare,
  faHiking,
  faPenFancy,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";

//estilos
const classes = require("./TimelineModal.module.css").default;

interface Props {
  actividadEtapas: Etapa[] | undefined;
  showModal: boolean;
  handleCloseModal: () => void;
}

/**
 * CobroModal Component
 * @description: Permite establecer que un servicio se va a cobrar y el total del mismo.
 * @date 06/03/2023.
 * @param Props actividadId que es el id de la actividad, showModal que es una variable de estado
 * que define si se muestra el modal o no, handleCloseModal es la funcion que cierra el modal.
 * @returns JSX del Modal.
 */

const TimelineModal = ({
  showModal,
  handleCloseModal,
  actividadEtapas,
}: Props) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Etapas de este servicio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            {actividadEtapas?.map((etapa) => {
              const fecha = etapa.fecha.substring(0, 10);
              const hora = etapa.fecha.substring(11, 16);
              let icono: any = null;
              switch (etapa.tipo_etapa_id) {
                case 1:
                  icono = faPlusSquare;
                  break;
                case 2:
                  icono = faCalendar;
                  break;
                case 3:
                  icono = faCheckSquare;
                  break;
                case 4:
                  icono = faPenFancy;
                  break;
                case 5:
                  icono = faHiking;
                  break;
              }
              return (
                <div
                  className={classes.singleTimelineArea}
                  key={etapa.tipo_etapa_id}
                >
                  <div
                    className={`${classes.timelineDate} wow fadeInLeft`}
                    data-wow-delay="0.1s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInLeft",
                    }}
                  >
                    <p>{fecha.toString()}</p>
                  </div>
                  <div className="row">
                    <TimelineCard
                      titulo={`${etapa.tipo_etapa.nombre}`}
                      descripcion={`${etapa.usuario.nombre} ${etapa.usuario.apellido_paterno} colocó el servicio como '${etapa.tipo_etapa.nombre}' a las ${hora}`}
                      icono={
                        <FontAwesomeIcon
                          icon={icono}
                          style={{
                            marginTop: "4px",
                            fontSize: "20px",
                            color: "white",
                          }}
                        />
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TimelineModal;
