import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
//estilos
const classes = require("./DashCard.module.css").default;

interface Props {
  nombre: string;
  total: number;
}

const DashCard = ({ nombre, total }: Props) => {
  const navigate = useNavigate();
  let link = `${nombre.toLowerCase()}s`;
  if (nombre === "Salida en falso") {
    link = "en_falso";
  } else if (nombre === "Por firmar") {
    link = "por_firmar";
  }
  return (
    <Card
      border="primary"
      className={classes.card}
      onClick={() => navigate(`/main/servicios/${link}`)}
    >
      {nombre === "Salida en falso" && (
        <Card.Header>Servicios con salida en falso</Card.Header>
      )}
      {nombre === "Por firmar" && (
        <Card.Header>Servicios por firmar</Card.Header>
      )}
      {nombre !== "Salida en falso" && nombre !== "Por firmar" && (
        <Card.Header>{`Servicios ${nombre}s`}</Card.Header>
      )}
      <Card.Body>
        <Card.Title>{total}</Card.Title>
        <Card.Text>Cantidad de servicios con estatus: {nombre}.</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default DashCard;
