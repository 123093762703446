import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useFetch from "../../hooks/useFetch";

//estilos
const classes = require("./ResetPassword.module.css").default;

const ValidateDifferentPasswords = (data: any) => {
  if (data.password === data.password_confirmation) return true;
  return false;
};

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  //Formulario
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      correo: "",
      password: "",
      password_confirmation: "",
      token: token,
    },
  });

  const { sendRequest, error } = useFetch();

  const changePassword = (data: any) => {
    if (!ValidateDifferentPasswords(data)) {
      setError("password_confirmation", {
        type: "custom",
        message: "Las contraseñas no coinciden",
      });
      return;
    }
    sendRequest(
      {
        url: `/reset-password`,
        method: "POST",
        body: data,
      },
      (variable: any) => {
        if (variable.message) {
          toast.success(variable.message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
        }
      }
    );
  };

  useEffect(() => {
    if (error) {
      toast.error(
        "El correo no existe o la contraseña es menor a ocho caracteres",
        {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  }, [error]);

  return (
    <div className={classes.maincontainer}>
      <div className={classes.main}>
        {/** Formulario para resetear password */}
        <div className={classes.signup}>
          <form onSubmit={handleSubmit(changePassword)}>
            <div className={classes.div}>
              <FontAwesomeIcon
                icon={faCogs}
                className={classes.cogs}
                size="4x"
                beatFade
              />
            </div>
            <label className={classes.label} htmlFor="chk" aria-hidden="true">
              SkyTech
            </label>
            <input
              {...register("correo", { maxLength: 100 })}
              className={classes.input}
              type="email"
              placeholder="Correo"
              autoComplete="off"
              required
            />
            <input
              {...register("password", {
                maxLength: 100,
              })}
              className={classes.input}
              type="password"
              placeholder="Contraseña"
              autoComplete="off"
              required
            />
            <input
              {...register("password_confirmation", {
                maxLength: 100,
                validate: { ValidateDifferentPasswords },
              })}
              className={classes.input}
              type="password"
              placeholder="Confirmar contraseña"
              autoComplete="off"
              required
            />
            {errors.password_confirmation && (
              <p style={{ color: "red" }}>
                {errors.password_confirmation.message}
              </p>
            )}
            <button className={classes.button} type="submit">
              Cambiar contraseña
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
