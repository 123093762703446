//librerías
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

//context
import { AuthContext } from "../../../../context/AuthContext";

//componentes
import { Button, Container, Spinner } from "react-bootstrap";
import {
  faPen,
  faSquarePlus,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";

//interfaces
import { PlataformaInterface } from "../../../../interfaces/PlataformaInterface";

//hooks
import useFetch from "../../../../hooks/useFetch";
import { ColumnDef } from "@tanstack/react-table";
import CustomTable from "../../../Formularios/CustomTable/CustomTable";
import ForbiddenMessage from "../../../Mensajes/ForbiddenMessage";

//estilos
const classes = require("./PlataformasTable.module.css").default;

type Plataforma = {
  id: number;
  nombre: string;
};

const PlataformasTable = () => {
  const authContext = useContext(AuthContext);
  //variables de estado
  const [plataformas, setPlataformas] = useState<PlataformaInterface[]>();
  const { sendRequest, error, setError } = useFetch();
  const navigate = useNavigate();

  //Función que trata los prueba traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setPlataformas(variable.data);
  }, []);

  //Función que llama todos los prueba paginados
  useEffect(() => {
    sendRequest(
      {
        url: "/plataforma",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  const columns = useMemo<ColumnDef<Plataforma>[]>(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        header: "Nombre",
        accessorFn: (row) => row.nombre,
        id: "nombre",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Button
              title="Editar plataforma"
              style={{ backgroundColor: "#2874A6", borderColor: "white" }}
              onClick={() =>
                navigate(
                  `/main/catalogos/plataformas/edit/${info.row.original.id}`
                )
              }
              disabled={!authContext.tokenCan("Plataforma:update")}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {/** Botón para asignar*/}
            <Button
              title="Asignar usuarios"
              style={{ backgroundColor: "#21618C", borderColor: "white" }}
              onClick={() =>
                navigate(
                  `/main/catalogos/plataformas/asignar/${info.row.original.id}`
                )
              }
              disabled={!authContext.tokenCan("Plataforma:assign")}
            >
              <FontAwesomeIcon icon={faTasks} />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigate, authContext]
  );

  //Mientras se carga el contenido se muestra un spinner
  if (!plataformas) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h2>Lista de plataformas</h2>
      {/** Botón para agregar usuario */}
      {authContext.tokenCan("Plataforma:store") && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            title="Agregar una plataforma"
            style={{
              backgroundColor: "#21618C",
              borderColor: "white",
              marginBottom: "10px",
            }}
            onClick={() => navigate(`/main/catalogos/plataformas/crear`)}
          >
            <FontAwesomeIcon icon={faSquarePlus} /> Nueva plataforma
          </Button>
        </div>
      )}
      {/** Alerta de plataformas */}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      <CustomTable
        {...{
          data: plataformas,
          columns,
        }}
      />
    </Container>
  );
};

export default PlataformasTable;
