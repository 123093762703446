import { useEffect, useState } from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";

//interfaces
import { AccesoriosInterface } from "../../../../interfaces/AccesoriosInterface";

//estilos
const classes = require("./AccesoriosDetalle.module.css").default;

/**
 * AccesoriosDetalle
 * @description: Componente que permite editar un accesorios.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de accesorioss.
 */

const AccesoriosDetalle = () => {
  const { idAccesorio } = useParams();
  const { sendRequest } = useFetch();
  const [accesorios, setAccesorios] = useState<AccesoriosInterface>();

  //Función que trata el detalle de accesorios.
  const transformData = (variable: any) => {
    setAccesorios(variable.data);
  };

  useEffect(() => {
    sendRequest(
      {
        url: `/accesorio/${idAccesorio}`,
      },
      transformData
    );
  }, [idAccesorio, sendRequest]);

  //mientras se carga el detalle se muestra un spinner
  if (!accesorios) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="danger" />
      </div>
    );
  }

  return (
    <Container style={{ marginTop: "20px" }}>
      <h2>Detalle del accesorio {idAccesorio} </h2>
      <Card style={{ width: "18rem" }} className={"mx-auto"}>
        <Card.Body>
          <Card.Title>{accesorios.nombre}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {`${accesorios.nombre}`}
          </Card.Subtitle>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AccesoriosDetalle;
