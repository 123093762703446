import { Alert } from "react-bootstrap";

type Props = {
  header: string;
  body: string;
  onCloseHandler: () => void;
};

const SuccessMessage = ({ header, body, onCloseHandler }: Props) => {
  return (
    <Alert variant="primary" onClose={onCloseHandler} dismissible>
      <Alert.Heading>{header}</Alert.Heading>
      <p>{body}</p>
    </Alert>
  );
};

export default SuccessMessage;
