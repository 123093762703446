import { Alert } from "react-bootstrap";

type Props = {
  onCloseHandler: () => void;
};

const ForbiddenMessage = ({ onCloseHandler }: Props) => {
  return (
    <Alert variant="danger" onClose={onCloseHandler} dismissible>
      <Alert.Heading>Acción no permitida</Alert.Heading>
      <p>
        No tienes permiso para realizar esta acción. Si consideras que se trata
        de un error, contacta al administrador.
      </p>
    </Alert>
  );
};

export default ForbiddenMessage;
