/** Custom Hook 'useFetch' 
* Descripción: Para realizar consultas a la BD. HTTP requests
  Parámetros: 
    @requestConfig Objeto con los datos para hacer la consulta (url, método, headers y body)
    @applyData Función que se le aplica a la variable @data una vez que recibió los datos.
  Return values:
    @1 Objeto con el state error para saber si falló la consulta, sendRequest es la función para hacer la consulta. 
*/

import { useState, useCallback, useContext } from "react";
import { API } from "../global/global";
import { AuthContext } from "../context/AuthContext";

export const urlMain = API;
interface RequestType {
  url: string;
  method?: string;
  headers?: {
    Accept: string;
    Authorization: string;
  };
  body?: Object;
}
const useFetch = () => {
  const authContext = useContext(AuthContext);
  const [error, setError] = useState<any>(null);
  const [serverMessage, setServerMessage] = useState<string>();

  const sendRequest = useCallback(
    async (
      requestConfig: RequestType,
      applyData: (variable: Object) => void
    ) => {
      setError(null);
      try {
        const response = await fetch(urlMain + requestConfig.url, {
          method: requestConfig.method ? requestConfig.method : "GET",
          headers: requestConfig.headers
            ? requestConfig.headers
            : {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + authContext.token,
              },
          body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
        });

        if (!response.ok) {
          setError(response.status);
          if (response.status === 500) {
            throw new Error("Request failed!");
          }
          if (response.status === 422) {
            const mensaje = await response.json();
            setServerMessage(mensaje.error);
            return;
          }
        }

        const data = await response.json();
        applyData(data);
      } catch (err: any) {
        console.log(err.message);
      }
    },
    [authContext]
  );

  return { error, sendRequest, setError, serverMessage, setServerMessage };
};
export default useFetch;
