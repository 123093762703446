import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useFetch from "../../../../hooks/useFetch";
import ForbiddenMessage from "../../../Mensajes/ForbiddenMessage";
import SuccessMessage from "../../../Mensajes/SuccessMessage";

/**
 * PlataformasCrear Component
 * @description: Componente que permite agregar un nuevo prueba.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de pruebas.
 */

const PlataformasCrear = () => {
  const [show, setShow] = useState(false);
  const { sendRequest, error, setError } = useFetch();

  //Formulario
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nombre: "",
    },
  });

  //Función que hace el POST para crear el prueba.
  const createRol = (data: any) => {
    sendRequest(
      {
        url: `/plataforma`,
        method: "POST",
        body: data,
      },
      (variable: any) => {
        setShow(true);
        reset({
          nombre: "",
        });
      }
    );
  };

  return (
    <Container>
      <h1>Agregar plataforma</h1>
      {/** Alerta de guardado */}
      {show && (
        <SuccessMessage
          header="Plataforma agregado"
          body="La plataforma ha sido agregada de manera correcta."
          onCloseHandler={() => setShow(false)}
        />
      )}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Formulario */}
      <Form onSubmit={handleSubmit(createRol)}>
        <Row className="mb-4">
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              {...register("nombre", { required: true, maxLength: 150 })}
            />
            {errors.nombre && (
              <Form.Text style={{ color: "red" }}>
                El nombre es obligatorio y no puede ser mayor a 150 caracteres.
              </Form.Text>
            )}
          </Form.Group>
        </Row>

        <Button
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
          }}
          type="submit"
        >
          Guardar
        </Button>
      </Form>
    </Container>
  );
};

export default PlataformasCrear;
