import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useFetch from "../../../hooks/useFetch";
import { useGetData } from "../../../hooks/useGetData";
import Select from "react-select";
import { EstadoInterface } from "../../../interfaces/EstadoInterface";
import { useState } from "react";
import { toast } from "react-toastify";

interface Props {
  actividadId: number;
  showModal: boolean;
  handleCloseModal: () => void;
  refetch: () => void;
}

/**
 * NoCobroModal Component
 * @description: Permite establecer que un servicio no se va a cobrar.
 * @date 06/03/2023.
 * @param Props actividadId que es el id de la actividad, showModal que es una variable de estado
 * que define si se muestra el modal o no, handleCloseModal es la funcion que cierra el modal.
 * @returns JSX del Modal.
 */

const NoCobroModal = ({
  showModal,
  handleCloseModal,
  actividadId,
  refetch,
}: Props) => {
  const { data: tiposCobro } = useGetData("/tipo_cobro?id[gt]=1", "tipo_cobro");
  //Formulario
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      observaciones: "",
      equipo: 0,
      servicio: 0,
      traslado: 0,
    },
  });
  const { sendRequest } = useFetch();

  const [tipocobro, setTipoCobro] = useState();

  //Función que hace el POST para agregar el costo a la actividad.
  const agregarCosto = (data: any) => {
    sendRequest(
      {
        url: `/cobro`,
        method: "POST",
        body: { ...data, actividad_id: actividadId, tipo_cobro_id: tipocobro },
      },
      (variable: any) => {
        toast.success("Se registró el tipo de cobro con éxito", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        refetch();
        handleCloseModal();
      }
    );
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Establecer cobro</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(agregarCosto)}>
          <div>
            <Form.Group className="mb-3">
              <Form.Label>Tipo de cobro</Form.Label>
              <Select
                placeholder="Selecciona una opción"
                options={tiposCobro?.map((cobro: EstadoInterface) => {
                  return {
                    value: cobro.id,
                    label: cobro.nombre,
                  };
                })}
                onChange={(cobro: any) => setTipoCobro(cobro.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Observaciones</Form.Label>
              <Form.Control
                {...register("observaciones", { maxLength: 200 })}
                as="textarea"
              />
              {errors.observaciones && (
                <Form.Text style={{ color: "red" }}>
                  Las observaciones no pueden ser mayor a 200 caracteres.
                </Form.Text>
              )}
            </Form.Group>
          </div>
          <Row className="mb-3">
            <Col>
              <Button
                variant="secondary"
                style={{ float: "right" }}
                onClick={handleCloseModal}
              >
                Cerrar
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  backgroundColor: "#21618C",
                  borderColor: "white",
                }}
                type="submit"
              >
                Confirmar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NoCobroModal;
