import {useEffect, useState} from 'react';

/**
 * Debounce Hook
 * @description: Hook que permite realizar un cooldown en los inputs del usuario.
 * @date 17/08/2022.
 * @params input (valor del input), time (tiempo que durará el cooldown)
 * @returns El valor del texto después del cooldown (por defecto 0.5 segs)
 */

export const useDebouncedValue = (input: string = '', time: number = 500) => {
  const [debouncedValue, setDeboundedValue] = useState(input);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDeboundedValue(input);
    }, time);
    return () => {
      clearTimeout(timeout);
    };
  }, [input, time]);
  return debouncedValue;
};
