import { useEffect, useState } from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";

//interfaces
import { ClientesInterface } from "../../../../interfaces/ClientesInterface";

//estilos
const classes = require("./ClienteDetalle.module.css").default;

/**
 * ClienteDetalle
 * @description: Componente que permite editar un cliente.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de clientes.
 */

const ClienteDetalle = () => {
  const { idCliente } = useParams();
  const { sendRequest } = useFetch();
  const [cliente, setCliente] = useState<ClientesInterface>();

  //Función que trata el detalle de cliente.
  const transformData = (variable: any) => {
    setCliente(variable.data);
  };

  useEffect(() => {
    sendRequest(
      {
        url: `/cliente/${idCliente}`,
      },
      transformData
    );
  }, [idCliente, sendRequest]);

  //mientras se carga el detalle se muestra un spinner
  if (!cliente) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="danger" />
      </div>
    );
  }

  return (
    <Container style={{ marginTop: "20px" }}>
      <h2>Detalle del cliente {idCliente} </h2>
      <Card style={{ width: "18rem" }} className={"mx-auto"}>
        <Card.Body>
          <Card.Title>{cliente.razon_social}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {`${cliente.nombre_comercial}`}
          </Card.Subtitle>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ClienteDetalle;
