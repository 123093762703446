//librerías
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

//context
import { AuthContext } from "../../../../context/AuthContext";

//componentes
import { Button, Container, Spinner } from "react-bootstrap";
import { faPen, faSquarePlus } from "@fortawesome/free-solid-svg-icons";

//interfaces
import { PermisoInterface } from "../../../../interfaces/PermisoInterface";

//hooks
import useFetch from "../../../../hooks/useFetch";
import { ColumnDef } from "@tanstack/react-table";
import CustomTable from "../../../Formularios/CustomTable/CustomTable";
import ForbiddenMessage from "../../../Mensajes/ForbiddenMessage";

//estilos
const classes = require("./PermisosTable.module.css").default;

type Permiso = {
  id: number;
  nombre: string;
};

const PermisosTable = () => {
  const authContext = useContext(AuthContext);
  //variables de estado
  const [permisos, setPermisos] = useState<PermisoInterface[]>();
  const { sendRequest, error, setError } = useFetch();
  const navigate = useNavigate();

  //Función que trata los prueba traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setPermisos(variable.data);
  }, []);

  //Función que llama todos los prueba paginados
  useEffect(() => {
    sendRequest(
      {
        url: "/permiso",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  const columns = useMemo<ColumnDef<Permiso>[]>(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        header: "Nombre",
        accessorFn: (row) => row.nombre,
        id: "nombre",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Button
              title="Editar permiso"
              style={{ backgroundColor: "#2874A6", borderColor: "white" }}
              onClick={() =>
                navigate(
                  `/main/catalogos/permisos/edit/${info.row.original.id}`
                )
              }
              disabled={!authContext.tokenCan("Permiso:update")}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigate, authContext]
  );

  //Mientras se carga el contenido se muestra un spinner
  if (!permisos) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h2>Lista de permisos</h2>
      {/** Botón para agregar usuario */}
      {authContext.tokenCan("Permiso:store") && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            title="Agregar un nuevo permiso"
            style={{
              backgroundColor: "#21618C",
              borderColor: "white",
              marginBottom: "10px",
            }}
            onClick={() => navigate(`/main/catalogos/permisos/crear`)}
          >
            <FontAwesomeIcon icon={faSquarePlus} /> Nuevo permiso
          </Button>
        </div>
      )}
      {/** Alerta de permisos */}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      <CustomTable
        {...{
          data: permisos,
          columns,
        }}
      />
    </Container>
  );
};

export default PermisosTable;
