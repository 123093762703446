//librerías
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faRedo, faTrashCan } from "@fortawesome/free-solid-svg-icons";

//context
import { AuthContext } from "../../../context/AuthContext";

//interfaces
import { ActividadIndex } from "../../../interfaces/ActividadIndexInterface";
//types
import { ServicioProgramado } from "../../../types/ServiciosProgramados";

//custom hooks
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import CustomTable from "../../Formularios/CustomTable/CustomTable";
import useDeleteActividad from "../../../hooks/useDeleteActividad";

//estilos
const classes = require("./ServiciosTable.module.css").default;

/**
 * ServiciosTable Component
 * @description: Componente que muestra el paginado de los servicios.
 * @date 14/11/2022.
 * @param Props Recibe los servicios en un arreglo, la función changeEtapa que actualiza la
 * etapa del contenido Home, la función changeServicio que actualiza el id del servicio al que se quiere saber su detalle.
 * @returns JSX de la tabla del servicio.
 */

const ServiciosProgramados = () => {
  const authContext = useContext(AuthContext);
  const [actividades, setActividades] = useState<ActividadIndex[]>();
  const { sendRequest } = useFetch();
  const navigate = useNavigate();
  const { eliminarServicio } = useDeleteActividad(setActividades);

  //Función que trata los servicios traidos de la BD.
  const transformData = useCallback((variable: any) => {
    setActividades(variable.data);
  }, []);

  //Función que llama todos los servicios paginados
  useEffect(() => {
    sendRequest(
      {
        url: "/actividad?includeAll=true&ultima_etapa[eq]=2",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  const columns = useMemo<ColumnDef<ServicioProgramado>[]>(
    () => [
      {
        header: "Órden de servicio",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Fecha y hora de la cita",
        accessorKey: "fecha",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Persona que capura",
        accessorFn: (row) => {
          let elemento = row.etapa.find(
            (element) => element.tipo_etapa_id === 1
          );
          if (elemento)
            return `${elemento?.usuario.nombre} ${elemento?.usuario.apellido_paterno}`;
          else return "";
        },
        id: "persona_captura",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Razón social",
        accessorFn: (row) => row.servicio.cliente.razon_social,
        id: "razon_social",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Folio",
        accessorFn: (row) => (row.servicio.folio ? row.servicio.folio : ""),
        id: "Folio",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Técnico",
        accessorFn: (row) =>
          `${row.usuario.nombre} ${row.usuario.apellido_paterno} ${row.usuario.apellido_materno}`,
        id: "tecnico",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Tipo de servicio",
        accessorFn: (row) => row.tipo_servicio.nombre,
        id: "tipo_servicio",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Económico",
        accessorFn: (row) => (row.unidad.economico ? row.unidad.economico : ""),
        id: "economico",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "VIN",
        accessorFn: (row) => (row.unidad.vin ? row.unidad.vin : ""),
        id: "vin",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Placas",
        accessorFn: (row) => (row.unidad.placa ? row.unidad.placa : ""),
        id: "placa",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Button
              title="Editar servicio"
              style={{ backgroundColor: "#5DADE2", borderColor: "white" }}
              onClick={() =>
                navigate(`/main/servicios/edit/${info.row.original.id}`)
              }
              disabled={!authContext.tokenCan("Servicio:update")}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button
              title="Reprogramar servicio"
              style={{ backgroundColor: "#2E86C1", borderColor: "white" }}
              onClick={() =>
                navigate(`/main/servicios/programar/${info.row.original.id}`)
              }
              disabled={!authContext.tokenCan("Servicio:update")}
            >
              <FontAwesomeIcon icon={faRedo} />
            </Button>
            {authContext.tokenCan("Actividad:delete") && (
              <Button
                title="Eliminar servicio"
                style={{ backgroundColor: "#21618C", borderColor: "white" }}
                onClick={() => eliminarServicio(info.row.original.id)}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </Button>
            )}
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigate, authContext, eliminarServicio]
  );

  //mientras se carga el detalle se muestra un spinner
  if (!actividades) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container style={{ paddingBottom: "1rem" }}>
      <h1>Servicios Programados</h1>
      <CustomTable
        {...{
          data: actividades,
          columns,
        }}
      />
    </Container>
  );
};

export default ServiciosProgramados;
