import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import Select from "react-select";

//interfaces
import { UsuarioInterface } from "../../../../interfaces/UsuarioInterface";
import { EstadoInterface } from "../../../../interfaces/EstadoInterface";
import { useGetData } from "../../../../hooks/useGetData";
import ForbiddenMessage from "../../../Mensajes/ForbiddenMessage";
import SuccessMessage from "../../../Mensajes/SuccessMessage";
import { RolInterface } from "../../../../interfaces/RolInterface";
import ServerMessage from "../../../Mensajes/ServerMessage";

//estilos
const classes = require("./UsuarioEdit.module.css").default;

/**
 * UusarioEdit Component
 * @description: Componente que permite editar un usuario.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de usuarios.
 */

const UsuarioEdit = () => {
  const { idUsuario } = useParams();
  const { sendRequest, setError, error, serverMessage, setServerMessage } =
    useFetch();
  const [usuario, setUsuario] = useState<UsuarioInterface>();
  const [show, setShow] = useState(false);
  const { data: estados } = useGetData("/estado", "estado");
  const { data: roles } = useGetData("/rol?id[gt]=1", "roles");
  const [estado, setEstado] = useState<number>();
  const [rol, setRol] = useState<number>();

  //Variables de formulario
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      nombre: usuario?.nombre,
      apellido_paterno: usuario?.apellido_paterno,
      apellido_materno: usuario?.apellido_materno,
      correo: usuario?.correo,
      telefono: usuario?.telefono,
      municipio: usuario?.municipio,
    },
  });

  //Función que trata el detalle de usuario.
  const transformData = useCallback(
    (variable: any) => {
      setUsuario(variable.data);
      setEstado(variable.data.estado?.id);
      reset(variable.data);
    },
    [reset]
  );

  //Se recibe el usuario a editar
  useEffect(() => {
    sendRequest(
      {
        url: `/usuario/${idUsuario}?includeRol=true`,
      },
      transformData
    );
  }, [idUsuario, sendRequest, transformData]);

  //Función que solicita la edición del usuario
  const updateUsuario = (data: any) => {
    sendRequest(
      {
        url: `/usuario/${idUsuario}`,
        method: "PATCH",
        body: { ...data, estado_id: estado, rol_id: rol },
      },
      (variable: any) => {
        setShow(true);
      }
    );
  };

  //Mientras se carga el contenido se muestra un spinner
  if (!usuario) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h2>Edición del usuario {idUsuario}</h2>
      {/** Alerta de guardado */}
      {show && (
        <SuccessMessage
          header="Usuario actualizado"
          body="El usuario ha sido actualizado de manera correcta."
          onCloseHandler={() => setShow(false)}
        />
      )}
      {/** Error del servidor */}
      {serverMessage && (
        <ServerMessage
          message={serverMessage}
          onCloseHandler={() => setServerMessage(undefined)}
        />
      )}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Formulario */}
      <Form onSubmit={handleSubmit(updateUsuario)}>
        <Row className="mb-3" md="4">
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Controller
              name="nombre"
              control={control}
              rules={{
                required: true,
                maxLength: 50,
              }}
              render={({ field }) => {
                return (
                  <Form.Control
                    {...field}
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                  />
                );
              }}
            />
          </Form.Group>

          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Apellido Paterno</Form.Label>
            <Controller
              name="apellido_paterno"
              control={control}
              rules={{
                required: true,
                maxLength: 50,
              }}
              render={({ field }) => {
                return (
                  <Form.Control
                    {...field}
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                  />
                );
              }}
            />
            {errors.apellido_paterno && (
              <Form.Text style={{ color: "red" }}>
                El apellido es obligatorio y no puede ser mayor a 50 caracteres.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Apellido Materno</Form.Label>
            <Controller
              name="apellido_materno"
              control={control}
              rules={{
                maxLength: 50,
              }}
              render={({ field }) => {
                return (
                  <Form.Control
                    {...field}
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                  />
                );
              }}
            />
            {errors.apellido_materno && (
              <Form.Text style={{ color: "red" }}>
                El apellido no puede ser mayor a 50 caracteres.
              </Form.Text>
            )}
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Correo</Form.Label>
            <Form.Control
              type="email"
              {...register("correo", {
                required: true,
                maxLength: 100,
              })}
            />
            {errors.correo && (
              <Form.Text style={{ color: "red" }}>Formato inválido.</Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Estado</Form.Label>
            <Select
              placeholder="Selecciona una opción"
              options={estados?.map((estado: EstadoInterface) => {
                return {
                  value: estado.id,
                  label: estado.nombre,
                };
              })}
              isClearable
              defaultValue={
                usuario.estado
                  ? {
                      value: usuario.estado.id,
                      label: usuario.estado.nombre,
                    }
                  : undefined
              }
              onChange={(estado: any) => setEstado(estado.value)}
            />
          </Form.Group>

          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Municipio</Form.Label>
            <Controller
              name="municipio"
              control={control}
              rules={{
                maxLength: 100,
              }}
              render={({ field }) => {
                return (
                  <Form.Control
                    {...field}
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                  />
                );
              }}
            />
            {errors.correo && (
              <Form.Text style={{ color: "red" }}>
                Se aceptan máximo 100 caracteres.
              </Form.Text>
            )}
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              type="text"
              {...register("telefono", {
                maxLength: 30,
              })}
            />
            {errors.correo && (
              <Form.Text style={{ color: "red" }}>
                Se aceptan máximo 30 caracteres.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} md="4">
            <Form.Label>Rol</Form.Label>
            <Select
              placeholder="Selecciona una opción"
              options={roles?.map((rol: RolInterface) => {
                return {
                  value: rol.id,
                  label: rol.nombre,
                };
              })}
              defaultValue={
                usuario.rol
                  ? {
                      value: usuario.rol.id,
                      label: usuario.rol.nombre,
                    }
                  : undefined
              }
              onChange={(rol: any) => setRol(rol.value)}
            />
          </Form.Group>
        </Row>
        <Button
          style={{ backgroundColor: "#21618C", borderColor: "white" }}
          type="submit"
        >
          Guardar cambios
        </Button>
      </Form>
    </Container>
  );
};

export default UsuarioEdit;
