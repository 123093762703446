import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { API } from "../../../global/global";
import { AuthContext } from "../../../context/AuthContext";
import { toast } from "react-toastify";

//estilos
const classes = require("./DropFilePDF.module.css").default;

interface Props {
  showModal: boolean;
  handleCloseModal: () => void;
  facturas: number[];
  refetch: () => void;
}

/**
 * NoCobroModal Component
 * @description: Permite establecer que un servicio no se va a cobrar.
 * @date 06/03/2023.
 * @param Props actividadId que es el id de la actividad, showModal que es una variable de estado
 * que define si se muestra el modal o no, handleCloseModal es la funcion que cierra el modal.
 * @returns JSX del Modal.
 */

const DropFilePDF = ({
  showModal,
  handleCloseModal,
  facturas,
  refetch,
}: Props) => {
  const [error, setError] = useState<string>();
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  //Función que sube el PDF al servidor
  const uploadPDF = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("pdf", file!);
    formData.append("actividades", JSON.stringify(facturas));
    formData.append("nombre", JSON.stringify(facturas) + ".pdf");

    await fetch(`${API}/save_factura`, {
      method: "post",
      headers: {
        Authorization: token !== null ? "Bearer " + token : "",
      },
      body: formData,
    })
      .catch(console.log)
      .then((response) => {
        setLoading(false);
        handleCloseModal();
        toast.success("Se ha subido la factura correctamente", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        refetch();
      });
  };

  const handleChange = (file: File) => {
    setError(undefined);
    setFile(file);
  };
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar una factura nueva</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && !loading && <p className={classes.error}>{error}</p>}
        {loading && (
          <div className={classes.spinnerContainer}>
            <Spinner animation="grow" variant="info" />
          </div>
        )}
        {!loading && (
          <FileUploader
            handleChange={handleChange}
            label="Cargar o soltar un archivo aquí"
            name="file"
            hoverTitle="Soltar un PDF aquí"
            types={["PDF"]}
            maxSize={2}
            onTypeError={() => {
              setError("Solo se aceptan archivos PDF");
              setFile(undefined);
            }}
            onSizeError={() => {
              setError("El tamaño máximo del archivo debe ser de 2MB");
              setFile(undefined);
            }}
          >
            <div className={classes.area}>
              <div className={classes.dropContainer}>
                Dar clic o soltar el archivo aqui <br />
                <FontAwesomeIcon icon={faFilePdf} />
              </div>
            </div>
          </FileUploader>
        )}
        <p>
          {file
            ? `Archivo subido: ${file.name}`
            : "Ningún archivo subido todavía"}
        </p>
        <Row className="mb-3">
          <Col>
            <Button
              variant="secondary"
              style={{ float: "right" }}
              onClick={handleCloseModal}
            >
              Cancelar
            </Button>
          </Col>
          <Col>
            <Button
              style={{
                backgroundColor: "#21618C",
                borderColor: "white",
              }}
              type="submit"
              disabled={file ? false : true}
              onClick={uploadPDF}
            >
              Subir
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default DropFilePDF;
