//librerías
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Button, Container, ProgressBar } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { API } from "../../../global/global";

//custom Hooks
import useFetch from "../../../hooks/useFetch";

//interfaces
import { ActividadShowAtendida } from "../../../interfaces/ActividadShowAtendida";
import ForbiddenMessage from "../../Mensajes/ForbiddenMessage";

//estilos
const classes = require("./ServiciosDetalle.module.css").default;

/**
 * ServicioDetalle Component
 * @description: Componente que muestra el contenido de un servicio de manera detallada.
 * @date 14/11/2022.
 * @param Props Recibe el id del servicio del cual hará la consulta del detalle al backend.
 * @returns JSX de la tabla del servicio.
 */

const ServicioDetalle = React.memo(() => {
  const { idActividad } = useParams();
  const { sendRequest } = useFetch();
  const [actividad, setActividad] = useState<ActividadShowAtendida>();
  const [loading, setLoading] = useState<boolean>(false);
  const authContext = useContext(AuthContext);
  const [error, setError] = useState<any>(null);

  //Función que trata los servicios traigos de la BD.
  const transformData = (variable: any) => {
    setActividad(variable.data[0]);
  };

  //Función que llama al PDF para descargarlo.
  const descargaPDF = async (idActividad: number) => {
    setLoading(true);
    const response = await fetch(`${API}/downloadPDF/${idActividad}`, {
      method: "get",
      headers: {
        Authorization: "Bearer " + authContext.token,
      },
    });

    if (!response.ok) {
      setError(response.status);
      if (response.status === 500) throw new Error("Request failed!");
      setLoading(false);
      return;
    }

    const data = await response.blob();

    //se crea el link con la descarga.
    let url = window.URL.createObjectURL(data),
      anchor = document.createElement("a");
    document.body.appendChild(anchor);
    anchor.href = url;
    anchor.download = `Orden_${idActividad}.pdf`;
    anchor.click();

    // Se elimna el link
    window.URL.revokeObjectURL(url);
    document.body.removeChild(anchor);
    setLoading(false);
  };

  //Función que llama al backend
  useEffect(() => {
    sendRequest(
      {
        url: `/actividad-completa/${idActividad}`,
      },
      transformData
    );
  }, [sendRequest, idActividad]);

  //mientras se carga el detalle se muestra un spinner
  if (!actividad) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container style={{ paddingBottom: "1rem" }}>
      <header>
        <h1 className={classes.titulo}>
          Servicio finalizado No. {actividad.id}
        </h1>
      </header>
      <article>
        {/** Tabla con datos generales */}
        <table className={`${classes.meta} ${classes.table}`}>
          <tbody>
            <tr>
              <th className={`${classes.cell} ${classes.th}`}>
                <span>Folio</span>
              </th>
              <td className={`${classes.cell} ${classes.td}`}>
                <span>
                  {actividad.servicio.folio ? actividad.servicio.folio : null}
                </span>
              </td>
            </tr>
            <tr>
              <th className={`${classes.cell} ${classes.th}`}>
                <span>Fecha del servicio</span>
              </th>
              <td className={`${classes.cell} ${classes.td}`}>
                <span>{actividad.fecha.toString()}</span>
              </td>
            </tr>
            <tr>
              <th className={`${classes.cell} ${classes.th}`}>
                <span>Coordenadas</span>
              </th>
              <td className={`${classes.cell} ${classes.td}`}>
                <span>{`(${actividad.servicio.latitud_cliente}, ${actividad.servicio.longitud_cliente})`}</span>
              </td>
            </tr>
          </tbody>
        </table>
        {/** Tabla con datos del cliente */}
        <h2 className={classes.subtitulo}>Datos del cliente </h2>
        <table className={classes.table}>
          <tbody>
            <tr>
              <th className={`${classes.cell} ${classes.th}`}>
                <span>Razon social</span>
              </th>
              <td className={`${classes.cell} ${classes.td}`}>
                <span>{actividad.servicio.cliente.razon_social}</span>
              </td>
            </tr>
            <tr>
              <th className={`${classes.cell} ${classes.th}`}>
                <span>Nombre comercial</span>
              </th>
              <td className={`${classes.cell} ${classes.td}`}>
                <span>{actividad.servicio.cliente.nombre_comercial}</span>
              </td>
            </tr>
            <tr>
              <th className={`${classes.cell} ${classes.th}`}>
                <span>Domicilio</span>
              </th>
              <td className={`${classes.cell} ${classes.td}`}>
                <span>{actividad.servicio.domicilio_cliente}</span>
              </td>
            </tr>
            {actividad.servicio.estado && (
              <tr>
                <th className={`${classes.cell} ${classes.th}`}>
                  <span>Localidad</span>
                </th>
                <td className={`${classes.cell} ${classes.td}`}>
                  <span>{actividad.servicio.estado.nombre}</span>
                </td>
              </tr>
            )}
            <tr>
              <th className={`${classes.cell} ${classes.th}`}>
                <span>Observaciones</span>
              </th>
              <td className={`${classes.cell} ${classes.td}`}>
                <span>{actividad.servicio.notas_cliente}</span>
              </td>
            </tr>
          </tbody>
        </table>
        {/** Tabla con datos de contacto */}
        {actividad.servicio.contacto.length > 0 && (
          <h2 className={classes.subtitulo}>Datos de contacto </h2>
        )}
        {actividad.servicio.contacto.map((contact) => (
          <table
            className={classes.table}
            key={`${contact.nombre}${contact.id}`}
          >
            <tbody>
              <tr>
                <th className={`${classes.cell} ${classes.th}`}>
                  <span>Nombre</span>
                </th>
                <td className={`${classes.cell} ${classes.td}`}>
                  <span>{contact.nombre}</span>
                </td>
              </tr>
              <tr>
                <th className={`${classes.cell} ${classes.th}`}>
                  <span>Correo</span>
                </th>
                <td className={`${classes.cell} ${classes.td}`}>
                  <span>{contact.correo}</span>
                </td>
              </tr>
              <tr>
                <th className={`${classes.cell} ${classes.th}`}>
                  <span>Teléfono fijo</span>
                </th>
                <td className={`${classes.cell} ${classes.td}`}>
                  <span>{contact.telefono_fijo}</span>
                </td>
              </tr>
              <tr>
                <th className={`${classes.cell} ${classes.th}`}>
                  <span>Teléfono móvil</span>
                </th>
                <td className={`${classes.cell} ${classes.td}`}>
                  <span>{contact.telefono_movil}</span>
                </td>
              </tr>
            </tbody>
          </table>
        ))}

        {/** Tabla con datos de cada unidad */}
        <div>
          <h2 className={classes.subtitulo}>Unidad {actividad.unidad.id}</h2>
          <div
            style={{
              padding: "20px",
              backgroundColor: "rgba(236, 204, 131, .2)",
              marginBottom: "20px",
            }}
          >
            {/** Tabla con datos generales de la unidad */}
            <table className={classes.table}>
              <tbody>
                <tr>
                  <th className={`${classes.cell} ${classes.th}`}>
                    <span>Tipo de servicio</span>
                  </th>
                  <td className={`${classes.cell} ${classes.td}`}>
                    <span>{actividad.tipo_servicio.nombre}</span>
                  </td>
                </tr>
                <tr>
                  <th className={`${classes.cell} ${classes.th}`}>
                    <span>Marca</span>
                  </th>
                  <td className={`${classes.cell} ${classes.td}`}>
                    <span>{actividad.unidad.marca_unidad?.nombre}</span>
                  </td>
                </tr>
                <tr>
                  <th className={`${classes.cell} ${classes.th}`}>
                    <span>Submarca</span>
                  </th>
                  <td className={`${classes.cell} ${classes.td}`}>
                    <span>{actividad.unidad.submarca}</span>
                  </td>
                </tr>
                <tr>
                  <th className={`${classes.cell} ${classes.th}`}>
                    <span>Modelo</span>
                  </th>
                  <td className={`${classes.cell} ${classes.td}`}>
                    <span>{actividad.unidad.modelo}</span>
                  </td>
                </tr>
                <tr>
                  <th className={`${classes.cell} ${classes.th}`}>
                    <span>Económico</span>
                  </th>
                  <td className={`${classes.cell} ${classes.td}`}>
                    <span>{actividad.unidad.economico}</span>
                  </td>
                </tr>
                <tr>
                  <th className={`${classes.cell} ${classes.th}`}>
                    <span>VIN</span>
                  </th>
                  <td className={`${classes.cell} ${classes.td}`}>
                    <span>{actividad.unidad.vin}</span>
                  </td>
                </tr>
                <tr>
                  <th className={`${classes.cell} ${classes.th}`}>
                    <span>Placas</span>
                  </th>
                  <td className={`${classes.cell} ${classes.td}`}>
                    <span>{actividad.unidad.placa}</span>
                  </td>
                </tr>
                <tr>
                  <th className={`${classes.cell} ${classes.th}`}>
                    <span>Color</span>
                  </th>
                  <td className={`${classes.cell} ${classes.td}`}>
                    <span>{actividad.unidad.color}</span>
                  </td>
                </tr>
                <tr>
                  <th className={`${classes.cell} ${classes.th}`}>
                    <span>Voltaje</span>
                  </th>
                  <td className={`${classes.cell} ${classes.td}`}>
                    <span>{actividad.unidad.voltaje}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            {/** Tabla de los datos del equipo */}
            <h2 className={classes.subtitulo}> Datos del equipo </h2>
            <table className={classes.table}>
              <tbody>
                {actividad.gps.length > 0 &&
                  actividad.gps.map((gp) => (
                    <tr key={`gps${gp.id}`}>
                      <th className={`${classes.cell} ${classes.th}`}>
                        <span>IMEI</span>
                      </th>
                      <td className={`${classes.cell} ${classes.td}`}>
                        <span>{gp.imei}</span>
                      </td>
                      <th className={`${classes.cell} ${classes.th}`}>
                        <span>Modelo</span>
                      </th>
                      <td className={`${classes.cell} ${classes.td}`}>
                        <span>{gp.modelo_gps.nombre}</span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {/** Datos del tecnico */}
            <h2 className={classes.subtitulo}>Datos del técnico </h2>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <th className={`${classes.cell} ${classes.th}`}>
                    <span>Nombre completo</span>
                  </th>
                  <td className={`${classes.cell} ${classes.td}`}>
                    <span>
                      {actividad.usuario.nombre}{" "}
                      {actividad.usuario.apellido_paterno}{" "}
                      {actividad.usuario.apellido_materno}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            {/** Tabla las verificaciones de la unidad */}
            <h2 className={classes.subtitulo}> Verificaciones </h2>
            <table className={classes.table}>
              <tbody>
                {actividad.actividad_verificacion.map((verificacion) => (
                  <tr key={`${verificacion.verificacion.nombre}`}>
                    <th className={`${classes.cell} ${classes.th}`}>
                      <span>{verificacion.verificacion.nombre}</span>
                    </th>
                    <td className={`${classes.cell} ${classes.td}`}>
                      <span>{verificacion.respuesta ? "SI" : "NO"}</span>
                    </td>
                  </tr>
                ))}
                <tr>
                  <th className={`${classes.cell} ${classes.th}`}>
                    <span>Anomalía</span>
                  </th>
                  <td className={`${classes.cell} ${classes.td}`}>
                    <span>{actividad.anomalia}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            {/** Tabla de las funciones del servicio */}
            {!actividad.tipo_servicio.revision &&
              actividad.actividad_funcion.length > 0 && (
                <>
                  <h2 className={classes.subtitulo}> Funciones </h2>
                  <ul>
                    {actividad.actividad_funcion.map((funcion, index) => (
                      <li
                        key={`${funcion.funcion.nombre}`}
                        style={{ textAlign: "left" }}
                      >
                        {funcion.funcion.nombre}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            {/** Tabla con las pruebas del servicio */}
            <h2 className={classes.subtitulo}> Pruebas </h2>
            <ul>
              {actividad.actividad_prueba.map((prueba: any) => (
                <li
                  key={`${prueba.prueba.nombre}`}
                  style={{ textAlign: "left" }}
                >
                  {prueba.prueba.nombre}
                </li>
              ))}
            </ul>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <th className={`${classes.cell} ${classes.th}`}>
                    <span>Persona en pruebas</span>
                  </th>
                  <td className={`${classes.cell} ${classes.td}`}>
                    <span>{actividad.persona_prueba.nombre}</span>
                  </td>
                </tr>
                <tr>
                  <th className={`${classes.cell} ${classes.th}`}>
                    <span>Descripción del servicio</span>
                  </th>
                  <td className={`${classes.cell} ${classes.td}`}>
                    <span>{actividad.descripcion_servicio}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            {/** Los accesorios que se utilizaron en la revision*/}
            {actividad.tipo_servicio.revision &&
              actividad.actividad_accesorio.length > 0 && (
                <>
                  <h2 className={classes.subtitulo}> Accesorios </h2>
                  {actividad.actividad_accesorio.map((accesorio) => (
                    <li
                      key={`${accesorio.accesorio.nombre}`}
                      style={{ textAlign: "left" }}
                    >
                      {accesorio.accesorio.nombre}
                    </li>
                  ))}
                </>
              )}
          </div>
          {/** Alerta de permisos */}
          {error === 403 && (
            <ForbiddenMessage onCloseHandler={() => setError(null)} />
          )}
          {loading && (
            <ProgressBar
              style={{ marginBottom: "1rem" }}
              label="Creando PDF..."
              animated
              now={100}
            />
          )}
          <Button
            style={{ backgroundColor: "#5DADE2", borderWidth: 0 }}
            onClick={() => descargaPDF(actividad.id)}
          >
            Descargar <FontAwesomeIcon icon={faFileArrowDown} />
          </Button>
        </div>
      </article>
    </Container>
  );
});

export default ServicioDetalle;
