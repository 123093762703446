import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import useFetch from "../../../../hooks/useFetch";
import { useGetData } from "../../../../hooks/useGetData";
import { EstadoInterface } from "../../../../interfaces/EstadoInterface";
import { RolInterface } from "../../../../interfaces/RolInterface";
import ForbiddenMessage from "../../../Mensajes/ForbiddenMessage";
import SuccessMessage from "../../../Mensajes/SuccessMessage";
import ServerMessage from "../../../Mensajes/ServerMessage";

/**
 * UsuarioCrear Component
 * @description: Componente que permite agregar un nuevo usuario.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de usuarios.
 */

const UsuarioCrear = () => {
  const [show, setShow] = useState(false);
  const { sendRequest, error, setError, serverMessage, setServerMessage } =
    useFetch();
  const { data: estados } = useGetData("/estado", "estado");
  const { data: roles } = useGetData("/rol?id[gt]=1", "roles");
  const [estado, setEstado] = useState<number>();
  const [rol, setRol] = useState<number>();

  //Formulario
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      correo: "",
      contrasena: "",
      municipio: "",
      telefono: "",
    },
  });

  //Función que hace el POST para crear el usuario.
  const createUsuario = (data: any) => {
    sendRequest(
      {
        url: `/usuario`,
        method: "POST",
        body: { ...data, estado, rol },
      },
      (variable: any) => {
        setShow(true);
        reset({
          nombre: "",
          apellido_paterno: "",
          apellido_materno: "",
          correo: "",
          contrasena: "",
          municipio: "",
          telefono: "",
        });
      }
    );
  };

  return (
    <Container>
      <h1>Agregar usuario</h1>
      {/** Alerta de guardado */}
      {show && (
        <SuccessMessage
          header="Usuario agregado"
          body="El usuario ha sido agregado de manera correcta."
          onCloseHandler={() => setShow(false)}
        />
      )}
      {/** Error del servidor */}
      {serverMessage && (
        <ServerMessage
          message={serverMessage}
          onCloseHandler={() => setServerMessage(undefined)}
        />
      )}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Formulario */}
      <Form onSubmit={handleSubmit(createUsuario)}>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Controller
              name="nombre"
              control={control}
              rules={{
                required: true,
                maxLength: 50,
              }}
              render={({ field }) => {
                return (
                  <Form.Control
                    {...field}
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                  />
                );
              }}
            />
            {errors.nombre && (
              <Form.Text style={{ color: "red" }}>
                El nombre es obligatorio y no puede ser mayor a 50 caracteres.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Apellido Paterno</Form.Label>
            <Controller
              name="apellido_paterno"
              control={control}
              rules={{
                required: true,
                maxLength: 50,
              }}
              render={({ field }) => {
                return (
                  <Form.Control
                    {...field}
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                  />
                );
              }}
            />
            {errors.apellido_paterno && (
              <Form.Text style={{ color: "red" }}>
                El apellido es obligatorio y no puede ser mayor a 50 caracteres.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Apellido Materno</Form.Label>
            <Controller
              name="apellido_materno"
              control={control}
              rules={{
                maxLength: 50,
              }}
              render={({ field }) => {
                return (
                  <Form.Control
                    {...field}
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                  />
                );
              }}
            />
            {errors.apellido_materno && (
              <Form.Text style={{ color: "red" }}>
                El apellido no puede ser mayor a 50 caracteres.
              </Form.Text>
            )}
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Correo</Form.Label>
            <Form.Control
              type="email"
              {...register("correo", {
                required: true,
                maxLength: 100,
              })}
            />
            {errors.correo && (
              <Form.Text style={{ color: "red" }}>Formato inválido.</Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              type="password"
              {...register("contrasena", {
                required: true,
                maxLength: 100,
              })}
            />
            {errors.contrasena && (
              <Form.Text style={{ color: "red" }}>
                Contraseña no válida.
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Estado</Form.Label>
            <Select
              placeholder="Selecciona una opción"
              options={estados?.map((estado: EstadoInterface) => {
                return {
                  value: estado.id,
                  label: estado.nombre,
                };
              })}
              onChange={(estado: any) => setEstado(estado.value)}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Municipio</Form.Label>
            <Controller
              name="municipio"
              control={control}
              rules={{
                maxLength: 100,
              }}
              render={({ field }) => {
                return (
                  <Form.Control
                    {...field}
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                  />
                );
              }}
            />
            {errors.correo && (
              <Form.Text style={{ color: "red" }}>
                Se aceptan máximo 100 caracteres.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              type="text"
              {...register("telefono", {
                maxLength: 30,
              })}
            />
            {errors.correo && (
              <Form.Text style={{ color: "red" }}>
                Se aceptan máximo 30 caracteres.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Rol</Form.Label>
            <Select
              placeholder="Selecciona una opción"
              options={roles?.map((rol: RolInterface) => {
                return {
                  value: rol.id,
                  label: rol.nombre,
                };
              })}
              onChange={(rol: any) => setRol(rol.value)}
            />
          </Form.Group>
        </Row>

        <Button
          type="submit"
          style={{ backgroundColor: "#21618C", borderColor: "white" }}
        >
          Guardar cambios
        </Button>
      </Form>
    </Container>
  );
};

export default UsuarioCrear;
