import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";

//interfaces
import { CentralesInterface } from "../../../../interfaces/CentralesInterface";

import ForbiddenMessage from "../../../Mensajes/ForbiddenMessage";
import SuccessMessage from "../../../Mensajes/SuccessMessage";
import ServerMessage from "../../../Mensajes/ServerMessage";

//estilos
const classes = require("./CentralEdit.module.css").default;

/**
 * UusarioEdit Component
 * @description: Componente que permite editar un central.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de centrales.
 */

const CentralEdit = () => {
  const { idCentral } = useParams();
  const { sendRequest, error, setError, serverMessage, setServerMessage } =
    useFetch();
  const [central, setCentral] = useState<CentralesInterface>();
  const [show, setShow] = useState(false);

  //Variables de formulario
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nombre: central?.nombre,
    },
  });

  //Función que trata el detalle de la central.
  const transformData = useCallback(
    (variable: any) => {
      setCentral(variable.data);
      reset(variable.data);
    },
    [reset]
  );

  //Se recibe el central a editar
  useEffect(() => {
    sendRequest(
      {
        url: `/central/${idCentral}`,
      },
      transformData
    );
  }, [idCentral, sendRequest, transformData]);

  //Función que solicita la edición del central
  const updateCentral = (data: any) => {
    sendRequest(
      {
        url: `/central/${idCentral}`,
        method: "PATCH",
        body: data,
      },
      (variable: any) => {
        setShow(true);
      }
    );
  };

  //Mientras se carga el contenido se muestra un spinner
  if (!central) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h2>Edición de la central {idCentral}</h2>
      {/** Alerta de guardado */}
      {show && (
        <SuccessMessage
          header="Central actualizado"
          body="La central ha sido actualizada de manera correcta."
          onCloseHandler={() => setShow(false)}
        />
      )}
      {/** Error del servidor */}
      {serverMessage && (
        <ServerMessage
          message={serverMessage}
          onCloseHandler={() => setServerMessage(undefined)}
        />
      )}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Formulario */}
      <Form onSubmit={handleSubmit(updateCentral)}>
        <Row className="mb-3">
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Controller
              name="nombre"
              control={control}
              rules={{
                required: true,
                maxLength: 100,
              }}
              render={({ field }) => {
                return (
                  <Form.Control
                    {...field}
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                  />
                );
              }}
            />
            {errors.nombre && (
              <Form.Text style={{ color: "red" }}>
                El nombre es obligatoria y no puede ser mayor a 100 caracteres.
              </Form.Text>
            )}
          </Form.Group>
        </Row>

        <Button
          style={{ backgroundColor: "#21618C", borderColor: "white" }}
          type="submit"
        >
          Guardar cambios
        </Button>
      </Form>
    </Container>
  );
};

export default CentralEdit;
