import React from "react";
import { Spinner } from "react-bootstrap";

export const Loading = () => {
  return (
      <div style={{marginTop: 10}}>
        <Spinner animation="grow" variant="danger" />
      </div>
  );
};
