/**
 * Screen Home
 * @description: Es la pantalla principal de la aplicación.
 * @date 14/11/2022.
 * @returns JSX del Screen.
 */

import { useCallback, useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import useFetch from "../../hooks/useFetch";
import DashCard from "../../components/Dashboard/DashCard/DashCard";
import { DashboardInterface } from "../../interfaces/DashboardInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthContext";
import { Container } from "react-bootstrap";

const classes = require("./Home.module.css").default;

type DateTime = {
  desde: string | null;
  hasta: string | null;
};

const Home = () => {
  const { sendRequest } = useFetch();
  const [conteo, setConteo] = useState<DashboardInterface[]>();
  const [timestamp, setTimestamp] = useState<DateTime>({
    desde: null,
    hasta: null,
  });
  const authContext = useContext(AuthContext);

  //Función que trata el detalle de prueba.
  const transformData = useCallback((variable: any) => {
    setConteo(
      variable.data.filter(
        (element: any) => element.nombre !== "Con fotos pendientes"
      )
    );
  }, []);

  //Se recibe el prueba a editar
  useEffect(() => {
    sendRequest(
      {
        url: `/dashboard`,
        method: "POST",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  //Filter information
  const lookForActividades = () => {
    if (!timestamp.desde || !timestamp.hasta) {
      toast.error("Es necesario colocar ambas fechas", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (timestamp.desde > timestamp.hasta) {
      toast.error("Es necesario que la primera fecha sea menor a la segunda", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    setConteo(undefined);
    sendRequest(
      {
        url: `/dashboard`,
        method: "POST",
        body: { from: timestamp.desde, to: timestamp.hasta },
      },
      transformData
    );
  };

  //Mientras se carga el contenido se muestra un spinner
  if (!conteo) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  if (!authContext.tokenCan("Web:view")) {
    return (
      <Container>
        <Alert variant="danger">
          <Alert.Heading>No tienes permiso para estar aquí</Alert.Heading>
          <p>
            No tienes permiso para acceder aquí, si piensas que es un error
            ponte en contacto con el administrador.
          </p>
        </Alert>
      </Container>
    );
  }

  return (
    <div style={{ marginTop: "10px" }}>
      <Container>
        <h2>Dashboard</h2>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Del:</Form.Label>
            <Form.Control
              type="date"
              min="2022-12-01"
              max="2030-12-31"
              value={timestamp.desde ? timestamp.desde : ""}
              onChange={(event) =>
                setTimestamp((prev) => {
                  return { ...prev, desde: event.target.value };
                })
              }
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Al:</Form.Label>
            <Form.Control
              type="date"
              min="2022-12-01"
              max="2030-12-31"
              value={timestamp.hasta ? timestamp.hasta : ""}
              onChange={(event) =>
                setTimestamp((prev) => {
                  return { ...prev, hasta: event.target.value };
                })
              }
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Button
              style={{
                backgroundColor: "#21618C",
                borderColor: "white",
              }}
              onClick={lookForActividades}
            >
              <FontAwesomeIcon icon={faSearch} /> Buscar
            </Button>
          </Form.Group>
        </Row>
        <div className="d-flex justify-content-center">
          {conteo.length === 0 && (
            <p>No se ha realizado ningún servicio este mes</p>
          )}
          {conteo.map((cont) => {
            return (
              <DashCard
                key={cont.nombre}
                nombre={cont.nombre}
                total={cont.total}
              />
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default Home;
