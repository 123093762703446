import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";

//interfaces
import { PlataformaInterface } from "../../../../interfaces/PlataformaInterface";

import ForbiddenMessage from "../../../Mensajes/ForbiddenMessage";
import SuccessMessage from "../../../Mensajes/SuccessMessage";

//estilos
const classes = require("./Plataformas.module.css").default;

/**
 * PermisosEdit Component
 * @description: Componente que permite editar un prueba.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de pruebas.
 */

const PlataformasEdit = () => {
  const { idPlataforma } = useParams();
  const { sendRequest, error, setError } = useFetch();
  const [plataforma, setPlataforma] = useState<PlataformaInterface>();
  const [show, setShow] = useState(false);

  //Variables de formulario
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nombre: plataforma?.nombre,
    },
  });

  //Función que trata el detalle de prueba.
  const transformData = useCallback(
    (variable: any) => {
      setPlataforma(variable.data);
      reset(variable.data);
    },
    [reset]
  );

  //Se recibe el prueba a editar
  useEffect(() => {
    sendRequest(
      {
        url: `/plataforma/${idPlataforma}`,
      },
      transformData
    );
  }, [idPlataforma, sendRequest, transformData]);

  //Función que solicita la edición del prueba
  const updatePrueba = (data: any) => {
    sendRequest(
      {
        url: `/plataforma/${idPlataforma}`,
        method: "PATCH",
        body: data,
      },
      (variable: any) => {
        setShow(true);
      }
    );
  };

  //Mientras se carga el contenido se muestra un spinner
  if (!plataforma) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h2>Edición plataforma {idPlataforma}</h2>
      {/** Alerta de guardado */}
      {show && (
        <SuccessMessage
          header="Permiso actualizado"
          body="El permiso ha sido actualizado de manera correcta."
          onCloseHandler={() => setShow(false)}
        />
      )}

      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Formulario */}
      <Form onSubmit={handleSubmit(updatePrueba)}>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              {...register("nombre", { required: true, maxLength: 150 })}
            />
            {errors.nombre && (
              <Form.Text style={{ color: "red" }}>
                El nombre es obligatorio y no puede ser mayor a 150 caracteres.
              </Form.Text>
            )}
          </Form.Group>
        </Row>

        <Button
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
          }}
          type="submit"
        >
          Guardar cambios
        </Button>
      </Form>
    </Container>
  );
};

export default PlataformasEdit;
