import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API } from "../global/global";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

export const useMutationData = (url: string, type: string, reload?: string) => {
  const authContext = useContext(AuthContext);
  const queryClient = useQueryClient();

  const mutationData = (newObject: void) => {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authContext.token,
      },
    };
    switch (type) {
      case "patch":
        return axios.patch(API + url, newObject, options);
      case "put":
        return axios.put(API + url, newObject, options);
      case "post":
      default:
        return axios.post(API + url, newObject, options);
    }
  };

  return useMutation((newObject) => mutationData(newObject), {
    onSuccess: () => {
      return queryClient.invalidateQueries([reload]);
    },
  });
};
