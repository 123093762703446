//librerías
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

//context
import { AuthContext } from "../../../../context/AuthContext";

//componentes
import { Button, Container, Spinner } from "react-bootstrap";
import {
  faPen,
  faSquarePlus,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";

//interfaces
import { ModeloGpsInterface } from "../../../../interfaces/ModeloGpsInterface";

//hooks
import useFetch from "../../../../hooks/useFetch";
import { ColumnDef } from "@tanstack/react-table";
import CustomTable from "../../../Formularios/CustomTable/CustomTable";
import ForbiddenMessage from "../../../Mensajes/ForbiddenMessage";
import { toast } from "react-toastify";

//estilos
const classes = require("./ModeloGpsTable.module.css").default;

const ModeloGpsTable = () => {
  const authContext = useContext(AuthContext);
  //variables de estado
  const [modeloGps, setModeloGps] = useState<ModeloGpsInterface[]>();
  const { sendRequest, error, setError } = useFetch();
  const navigate = useNavigate();

  //Función que trata los modeloGps traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setModeloGps(variable.data);
  }, []);

  //Función que llama todos los modeloGps paginados
  useEffect(() => {
    sendRequest(
      {
        url: "/modelo_gps",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  //Función que desactiva a un modeloGps.
  const changeModeloGpsStatus = useCallback(
    (idModeloGps: number) => {
      if (
        window.confirm(
          "¿Seguro que desea cambiar el estatus del modelo de GPS?"
        )
      ) {
        sendRequest(
          {
            url: `/modelo_gps/${idModeloGps}`,
            method: "DELETE",
          },
          (variable: any) => {
            setModeloGps([]);
            toast.success("El modelo de GPS ha cambiado de estatus.", {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            sendRequest(
              {
                url: "/modelo_gps",
              },
              transformData
            );
          }
        );
      }
    },
    [sendRequest, transformData]
  );

  const columns = useMemo<ColumnDef<ModeloGpsInterface>[]>(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        header: "Nombre",
        accessorFn: (row) => row.nombre,
        id: "nombre",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Estatus",
        cell: (info) => info.getValue(),
        accessorFn: (row) => (row.estatus ? "Activo" : "Inactivo"),
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Button
              title="Editar modelo de gps"
              style={{ backgroundColor: "#2874A6", borderColor: "white" }}
              onClick={() =>
                navigate(
                  `/main/catalogos/modeloGps/edit/${info.row.original.id}`
                )
              }
              disabled={!authContext.tokenCan("ModeloGps:update")}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {/** Botón para ver eliminar*/}
            <Button
              title={
                info.row.original.estatus
                  ? "Deshabilitar modelo de GPS"
                  : "Habilitar modelo de GPS"
              }
              style={{ backgroundColor: "#0d5475", borderColor: "white" }}
              disabled={!authContext.tokenCan("ModeloGps:delete")}
              onClick={() => changeModeloGpsStatus(info.row.original.id)}
            >
              <FontAwesomeIcon
                icon={info.row.original.estatus ? faToggleOn : faToggleOff}
              />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [changeModeloGpsStatus, navigate, authContext]
  );

  //Mientras se carga el contenido se muestra un spinner
  if (!modeloGps) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h1>Lista de modelos de GPS</h1>
      {/** Botón para agregar usuario */}
      {authContext.tokenCan("ModeloGps:store") && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            title="Agregar un nuevo modelo de GPS"
            style={{
              backgroundColor: "#21618C",
              borderColor: "white",
              marginBottom: "10px",
            }}
            onClick={() => navigate(`/main/catalogos/modeloGps/crear`)}
          >
            <FontAwesomeIcon icon={faSquarePlus} /> Nuevo modelo de GPS
          </Button>
        </div>
      )}

      {/** Alerta de permisos */}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      <CustomTable
        {...{
          data: modeloGps,
          columns,
        }}
      />
    </Container>
  );
};

export default ModeloGpsTable;
