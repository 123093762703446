import { useNavigate } from "react-router-dom";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { UnidadInterface } from "../../../interfaces/UnidadInterface";

interface Props {
  handleCloseModal: () => void;
  unidad: UnidadInterface;
  actividades: any[];
}

const classes = require("./UnidadServiciosModal.module.css").default;

/**
 * UnidadServiciosModal Component
 * @description: Componente que permite ver los servicios de una unidad.
 * @date 16/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de clientes.
 */
export const UnidadServiciosModal = ({
  handleCloseModal,
  unidad,
  actividades,
}: Props) => {
  const navigate = useNavigate();
  return (
    <Modal
      show={true}
      onHide={handleCloseModal}
      className="table-responsive"
      dialogClassName={classes.modal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Unidad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <div
            className="table-responsive"
            style={{
              maxHeight: "300px",
            }}
          >
            <table className="table table-striped">
              <thead style={{ backgroundColor: "#04144c", color: "white" }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Fecha del servicio</th>
                  <th scope="col">Razón social</th>
                  <th scope="col">Tipo de servicio</th>
                  <th scope="col">Descripción del servicio</th>
                  <th scope="col">Observaciones de la revisión</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {actividades
                  ?.filter(
                    (actividad: any) =>
                      actividad.unidad.id === unidad.id &&
                      actividad.tipo_servicio.revision === true
                  )
                  .map((a) => (
                    <tr
                      key={`servicios-unidad-${a.id}`}
                      className="table-secondary"
                    >
                      <td>{a.id}</td>
                      <td>{a.fecha}</td>
                      <td>{a.servicio.cliente.razon_social}</td>
                      <td>{a.tipo_servicio.nombre}</td>
                      <td>{a.descripcion_servicio}</td>
                      <td>{a.manipulacion_observaciones}</td>
                      <td>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <Button
                            title="Ver este servicio"
                            style={{
                              backgroundColor: "#2E86C1",
                              borderColor: "white",
                            }}
                            onClick={() =>
                              navigate(`/main/servicios/details/${a.id}`)
                            }
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Row>

        <Row className="mb-3">
          <Col>
            <Button
              variant="secondary"
              style={{ float: "right" }}
              onClick={handleCloseModal}
            >
              Cerrar
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
