import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select, { SingleValue } from "react-select";
import { useGetData } from "../../../hooks/useGetData";
import { MarcaUnidadInterface } from "../../../interfaces/MarcaUnidadInterface";
import { useMutationData } from "../../../hooks/useMutationData";
import { toast } from "react-toastify";

interface Props {
  idCliente: number;
  showModal: boolean;
  handleCloseModal: () => void;
}

/**
 * UnidadCrearModal Component
 * @description: Componente que permite crear una unidad.
 * @date 16/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de clientes.
 */
export const UnidadCrearModal = ({
  idCliente,
  showModal,
  handleCloseModal,
}: Props) => {
  const { data } = useGetData("/marca_unidad", "marca_unidad");

  const mutateUnidad = useMutationData(
    "/unidad",
    "post",
    `unidades${idCliente}`
  );

  //Función que hace el POST para crear la unidad.
  const crearUnidad = async (data: any) => {
    try {
      await mutateUnidad.mutateAsync(data);
      toast.success("Se creó la unidad con éxito", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error: any) {
      toast.error(error.response.data.error, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    handleCloseModal();
  };

  //Formulario
  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ticket: null,
      submarca: "",
      modelo: "",
      economico: "",
      vin: "",
      placa: "",
      color: "",
      voltaje: "",
      marca_unidad_id: "",
      cliente_id: idCliente,
    },
  });

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Unidad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/** Para Nacional Monte de Piedad */}
          {idCliente === 455 && (
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Ticket</Form.Label>
                <Form.Control
                  {...register("ticket", { maxLength: 50, required: true })}
                  placeholder="Ingresar el ticket"
                  type="number"
                />
                {errors.ticket && (
                  <Form.Text style={{ color: "red" }}>
                    El ticket no puede ser mayor a 50 caracteres.
                  </Form.Text>
                )}
              </Form.Group>
            </Row>
          )}
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Submarca</Form.Label>
              <Controller
                name="submarca"
                control={control}
                rules={{
                  maxLength: 50,
                }}
                render={({ field }) => {
                  return (
                    <Form.Control
                      {...field}
                      onChange={(e) =>
                        field.onChange(e.target.value.toUpperCase())
                      }
                      placeholder="Ingresar la submarca"
                    />
                  );
                }}
              />
              {errors.submarca && (
                <Form.Text style={{ color: "red" }}>
                  La submarca no puede ser mayor a 50 caracteres.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Modelo</Form.Label>
              <Controller
                name="modelo"
                control={control}
                rules={{
                  maxLength: 50,
                }}
                render={({ field }) => {
                  return (
                    <Form.Control
                      {...field}
                      onChange={(e) =>
                        field.onChange(e.target.value.toUpperCase())
                      }
                      placeholder="Ingresar el modelo"
                    />
                  );
                }}
              />
              {errors.modelo && (
                <Form.Text style={{ color: "red" }}>
                  El modelo no puede ser mayor a 50 caracteres.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Económico</Form.Label>
              <Controller
                name="economico"
                control={control}
                rules={{
                  maxLength: 50,
                  pattern: /^[0-Z]*$/,
                }}
                render={({ field }) => {
                  return (
                    <Form.Control
                      {...field}
                      onChange={(e) =>
                        field.onChange(e.target.value.toUpperCase())
                      }
                      placeholder="Ingresar el económico"
                    />
                  );
                }}
              />
              {errors.economico && (
                <Form.Text style={{ color: "red" }}>
                  El económico no puede ser mayor a 50 caracteres. No se admiten
                  caracteres especiales ni espacios.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>VIN</Form.Label>
              <Controller
                name="vin"
                control={control}
                rules={{
                  maxLength: 50,
                  pattern: /^[0-Z]*$/,
                }}
                render={({ field }) => {
                  return (
                    <Form.Control
                      {...field}
                      onChange={(e) =>
                        field.onChange(e.target.value.toUpperCase())
                      }
                      placeholder="Ingresar el VIN"
                    />
                  );
                }}
              />
              {errors.vin && (
                <Form.Text style={{ color: "red" }}>
                  El VIN no puede ser mayor a 50 caracteres. No se admiten
                  caracteres especiales ni espacios.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Placa</Form.Label>
              <Controller
                name="placa"
                control={control}
                rules={{
                  maxLength: 50,
                  pattern: /^[0-Z]*$/,
                }}
                render={({ field }) => {
                  return (
                    <Form.Control
                      {...field}
                      onChange={(e) =>
                        field.onChange(e.target.value.toUpperCase())
                      }
                      placeholder="Ingresar la placa"
                    />
                  );
                }}
              />
              {errors.placa && (
                <Form.Text style={{ color: "red" }}>
                  La placa no puede ser mayor a 50 caracteres. No se admiten
                  caracteres especiales ni espacios.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Color</Form.Label>
              <Controller
                name="color"
                control={control}
                rules={{
                  maxLength: 50,
                }}
                render={({ field }) => {
                  return (
                    <Form.Control
                      {...field}
                      onChange={(e) =>
                        field.onChange(e.target.value.toUpperCase())
                      }
                      placeholder="Ingresar la submarca"
                    />
                  );
                }}
              />
              {errors.color && (
                <Form.Text style={{ color: "red" }}>
                  El color no puede ser mayor a 50 caracteres.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Voltaje</Form.Label>
              <Form.Control
                {...register("voltaje", { maxLength: 50 })}
                placeholder="Ingresar el voltaje"
                type="number"
              />
              {errors.voltaje && (
                <Form.Text style={{ color: "red" }}>
                  El voltaje no puede ser mayor a 50 caracteres.
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Marca de la unidad</Form.Label>
              <Select
                onChange={(value: SingleValue<{ value: any }>) => {
                  setValue("marca_unidad_id", value?.value);
                }}
                placeholder="Selecciona una opción"
                isClearable
                options={data?.map((marcaUnidad: MarcaUnidadInterface) => {
                  return {
                    value: marcaUnidad.id,
                    label: marcaUnidad.nombre,
                  };
                })}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Col>
              <Button
                variant="secondary"
                style={{ float: "right" }}
                onClick={handleCloseModal}
              >
                Cerrar
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  backgroundColor: "#5DADE2",
                  borderColor: "white",
                }}
                onClick={handleSubmit(crearUnidad)}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
