import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";

//interfaces
import { RolInterface } from "../../../../interfaces/RolInterface";
import ForbiddenMessage from "../../../Mensajes/ForbiddenMessage";
import SuccessMessage from "../../../Mensajes/SuccessMessage";

//estilos
const classes = require("./RolEdit.module.css").default;

/**
 * UusarioEdit Component
 * @description: Componente que permite editar un prueba.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de pruebas.
 */

const RolesEdit = () => {
  const { idRol } = useParams();
  const { sendRequest, error, setError } = useFetch();
  const [rol, setRol] = useState<RolInterface>();
  const [show, setShow] = useState(false);

  //Variables de formulario
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nombre: rol?.nombre,
      descripcion: rol?.descripcion,
    },
  });

  //Función que trata el detalle de prueba.
  const transformData = useCallback(
    (variable: any) => {
      setRol(variable.data);
      reset(variable.data);
    },
    [reset]
  );

  //Se recibe el prueba a editar
  useEffect(() => {
    sendRequest(
      {
        url: `/rol/${idRol}`,
      },
      transformData
    );
  }, [idRol, sendRequest, transformData]);

  //Función que solicita la edición del prueba
  const updatePrueba = (data: any) => {
    sendRequest(
      {
        url: `/rol/${idRol}`,
        method: "PATCH",
        body: data,
      },
      (variable: any) => {
        setShow(true);
      }
    );
  };

  //Mientras se carga el contenido se muestra un spinner
  if (!rol) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h2>Edición del rol {idRol}</h2>
      {/** Alerta de guardado */}
      {show && (
        <SuccessMessage
          header="Prueba actualizada"
          body="La prueba ha sido actualizada de manera correcta."
          onCloseHandler={() => setShow(false)}
        />
      )}

      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Formulario */}
      <Form onSubmit={handleSubmit(updatePrueba)}>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              {...register("nombre", { required: true, maxLength: 30 })}
            />
            {errors.nombre && (
              <Form.Text style={{ color: "red" }}>
                El nombre es obligatorio y no puede ser mayor a 30 caracteres.
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} md="8" className="mb-3">
            <Form.Label>Descripcion</Form.Label>
            <Form.Control
              {...register("descripcion", { required: true, maxLength: 50 })}
            />
            {errors.nombre && (
              <Form.Text style={{ color: "red" }}>
                La descripcion es obligatoria y no puede ser mayor a 50
                caracteres.
              </Form.Text>
            )}
          </Form.Group>
        </Row>

        <Button
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
          }}
          type="submit"
        >
          Guardar cambios
        </Button>
      </Form>
    </Container>
  );
};

export default RolesEdit;
