import { Navigate, Outlet } from "react-router-dom";

type Props = {
  isAllowed: boolean;
  redirectPath: string;
  children: any;
};
const ProtectedRoute = ({
  isAllowed,
  redirectPath = "/main",
  children,
}: Props) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
