import { useEffect, useState } from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";

//interfaces
import { ModeloGpsInterface } from "../../../../interfaces/ModeloGpsInterface";

//estilos
const classes = require("./ModeloGpsDetalle.module.css").default;

/**
 * ModeloGpsDetalle
 * @description: Componente que permite editar un modeloGps.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de modeloGpss.
 */

const ModeloGpsDetalle = () => {
  const { idModeloGps } = useParams();
  const { sendRequest } = useFetch();
  const [modeloGps, setModeloGps] = useState<ModeloGpsInterface>();

  //Función que trata el detalle de modeloGps.
  const transformData = (variable: any) => {
    setModeloGps(variable.data);
  };

  useEffect(() => {
    sendRequest(
      {
        url: `/modelo_gps/${idModeloGps}`,
      },
      transformData
    );
  }, [idModeloGps, sendRequest]);

  //mientras se carga el detalle se muestra un spinner
  if (!modeloGps) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="danger" />
      </div>
    );
  }

  return (
    <Container style={{ marginTop: "20px" }}>
      <h2>Detalle del modelo de GPS {idModeloGps} </h2>
      <Card style={{ width: "18rem" }} className={"mx-auto"}>
        <Card.Body>
          <Card.Title>{modeloGps.nombre}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {`${modeloGps.nombre}`}
          </Card.Subtitle>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ModeloGpsDetalle;
