//librerías
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  faFileCircleCheck,
  faReceipt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import { Button, Container, ProgressBar, Spinner } from "react-bootstrap";

//custom hooks
import useFetch from "../../../hooks/useFetch";

//componentes
import CustomTable from "../../Formularios/CustomTable/CustomTable";

//interfaces
import { ActividadIndexCobrado } from "../../../interfaces/ActividadIndexCobrado";

//types
import { ServicioPagado } from "../../../types/ServicioPagado";
import { API } from "../../../global/global";
import { AuthContext } from "../../../context/AuthContext";
import TicketPagoModal from "../../Formularios/TicketPagoModal/TicketPagoModal";
//estilos
const classes = require("./ServiciosTable.module.css").default;

/**
 * ServiciosCobrados Component
 * @description: Componente que muestra el paginado de los servicios que se han cobrado.
 * @date 06/03/2023.
 * @param Props Ninguno
 * @returns JSX de la tabla de servicios por cobrar.
 */

const ServiciosPagados = () => {
  const { sendRequest } = useFetch();
  const [actividades, setActividades] = useState<ActividadIndexCobrado[]>();
  const authContext = useContext(AuthContext);
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
  const [globalService, setGlobalService] = useState<ServicioPagado>();

  //Función que trata los servicios traidos de la BD.
  const transformData = useCallback((variable: any) => {
    setActividades(variable.data);
  }, []);

  //Get pagos
  const getPagos = (): void => {
    sendRequest(
      {
        url: "/actividad?ultima_etapa[gte]=3&onlyPagoNotNull=true&includeAll=true",
      },
      transformData
    );
  };

  //Función que llama los servicios
  useEffect(getPagos, [sendRequest, transformData]);

  //Función que muestra el PDF del servicio en otra pestaña.
  const mostrarPDF = useCallback(
    async (idActividad: number) => {
      setLoadingPDF(true);
      const response = await fetch(`${API}/get_factura/${idActividad}`, {
        method: "get",
        headers: {
          Authorization: "Bearer " + authContext.token,
        },
      });

      if (!response.ok) {
        if (response.status === 500) throw new Error("Request failed!");
        setLoadingPDF(false);
        return;
      }

      const data = await response.blob();

      //se crea el link con la descarga.
      let url = window.URL.createObjectURL(data),
        anchor = document.createElement("a");
      document.body.appendChild(anchor);
      anchor.href = url;
      anchor.download = `Factura_${idActividad}.pdf`;
      anchor.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(anchor);
      setLoadingPDF(false);
    },
    [authContext.token]
  );

  //Definicion de las columnas de la tabla
  const columns = useMemo<ColumnDef<ServicioPagado>[]>(
    () => [
      {
        header: "Órden de servicio",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Fecha y hora de la cita",
        accessorKey: "fecha",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Razón social",
        accessorFn: (row) => row.servicio.cliente.razon_social,
        id: "razon_social",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Técnico",
        accessorFn: (row) =>
          `${row.usuario.nombre} ${row.usuario.apellido_paterno} ${row.usuario.apellido_materno}`,
        id: "tecnico",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Tipo de servicio",
        accessorFn: (row) => row.tipo_servicio.nombre,
        id: "tipo_servicio",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Económico",
        accessorFn: (row) => (row.unidad.economico ? row.unidad.economico : ""),
        id: "economico",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Placas",
        accessorFn: (row) => (row.unidad.placa ? row.unidad.placa : ""),
        id: "placa",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Descripción",
        accessorFn: (row) =>
          row.descripcion_servicio ? row.descripcion_servicio : "",
        id: "descripcion",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Tipo de pago",
        accessorFn: (row) => row.pago.tipo_pago.nombre,
        id: "tipo_pago",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Factura",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            {/** Boton de despliegue del PDF */}
            {info.row.original.factura ? (
              <Button
                title="Obtener la factura de este servicio"
                style={{ backgroundColor: "#5DADE2", borderColor: "white" }}
                disabled={loadingPDF}
                onClick={() => mostrarPDF(info.row.original.id)}
              >
                <FontAwesomeIcon icon={faFileCircleCheck} />
              </Button>
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} />
            )}
          </div>
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            {/** Boton de recibo */}
            <Button
              title="Ver recibo"
              style={{ backgroundColor: "#5DADE2", borderColor: "white" }}
              disabled={loadingPDF}
              onClick={() => setGlobalService(info.row.original)}
            >
              <FontAwesomeIcon icon={faReceipt} />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [mostrarPDF, loadingPDF]
  );

  //Mientras se carga el detalle se muestra un spinner
  if (!actividades) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container style={{ paddingBottom: "1rem" }}>
      <h1>Servicios pagados a Proveedores</h1>
      {/** ProgressBar */}
      {loadingPDF && (
        <ProgressBar
          style={{ marginBottom: "1rem" }}
          label="Creando PDF..."
          animated
          now={100}
        />
      )}
      {/** Tabla de actividades */}
      <CustomTable
        {...{
          data: actividades,
          columns,
        }}
      />
      <TicketPagoModal
        servicio={globalService}
        handleCloseModal={() => setGlobalService(undefined)}
        getPagos={getPagos}
      />
    </Container>
  );
};

export default ServiciosPagados;
