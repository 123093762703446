import { useCallback, useEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
//interfaces
import { PruebasxTipoServicioInterface } from "../../../../interfaces/PruebasxTipoServicioInterface";
import SuccessMessage from "../../../Mensajes/SuccessMessage";

//estilos
const classes = require("./TipoServicioAsignarPrueba.module.css").default;

const TipoServicioAsignarPrueba = () => {
  const { idTipoServicio } = useParams();
  const { sendRequest } = useFetch();
  const [pruebas, setPruebas] = useState<PruebasxTipoServicioInterface[]>();
  const [show, setShow] = useState(false);

  //Función que trata los prueba traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setPruebas(variable.data);
  }, []);

  //Función que trae los permisos por rol
  useEffect(() => {
    sendRequest(
      {
        url: "/pruebas_tipo_servicio",
        method: "POST",
        body: { tipo_servicio_id: idTipoServicio },
      },
      transformData
    );
  }, [sendRequest, transformData, idTipoServicio]);

  //Actualizar permisos
  const guardarPermisos = (event: any) => {
    event.preventDefault();
    sendRequest(
      {
        url: "/savepruebas_tipo_servicio",
        method: "POST",
        body: { tipo_servicio_id: idTipoServicio, pruebas: pruebas },
      },
      () => {
        setShow(true);
        window.scrollTo(0, 0);
      }
    );
  };

  //Mientras se carga el contenido se muestra un spinner
  if (!pruebas) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h1>Lista de pruebas para el tipo de servicio {idTipoServicio}</h1>
      {/** Alerta de guardado */}
      {show && (
        <SuccessMessage
          header="Pruebas asignadas"
          body="Se han asigando las pruebas de manera correcta"
          onCloseHandler={() => setShow(false)}
        />
      )}
      <Form onSubmit={guardarPermisos}>
        {pruebas.map((prueba, index) => (
          <div key={prueba.id} className="mb-3">
            <Form.Check
              type={"checkbox"}
              id={`default-checkbox`}
              label={prueba.nombre}
              style={{ textAlign: "left" }}
              defaultChecked={prueba.pivot ? true : false}
              onChange={(event) => {
                if (event.target.checked) {
                  pruebas[index]["pivot"] = {
                    tipo_servicio_id: +idTipoServicio!,
                    prueba_id: prueba.id,
                  };
                } else {
                  delete pruebas[index]["pivot"];
                }
              }}
            />
          </div>
        ))}
        <Button
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
            marginBottom: "20px",
          }}
          type="submit"
        >
          Guardar cambios
        </Button>
      </Form>
    </Container>
  );
};

export default TipoServicioAsignarPrueba;
