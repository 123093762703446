import { Outlet } from "react-router-dom";

const Centrales = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default Centrales;
