//librerías
import { Routes, Route, Navigate } from "react-router-dom";

//componentes
import Login from "../Login/Login";
import Main from "../Main/Main";
import Home from "../Home/Home";
import Servicios from "../Servicios/Servicios";
import Reportes from "../Reportes/Reportes";
import Busqueda from "../Busqueda/Busqueda";
import Catalogos from "../Catalogos/Catalogos";
import Usuarios from "../Usuarios/Usuarios";
import Clientes from "../Clientes/Clientes";
import TipoServicio from "../TipoServicio/TipoServicio";
import MarcaUnidad from "../MarcaUnidad/MarcaUnidad";
import ResetPassword from "../ResetPassword/ResetPassword";
import ProtectedRoute from "../../components/ProtectedRoute/ProtectedRoute";

import ServiciosCapturados from "../../components/Servicios/ServiciosTable/ServiciosCapturados";
import ServiciosProgramados from "../../components/Servicios/ServiciosTable/ServiciosProgramados";
import ServiciosAtendidos from "../../components/Servicios/ServiciosTable/ServiciosAtendidos";
import ServiciosPorCobrar from "../../components/Servicios/ServiciosTable/ServiciosPorCobrar";
import ServiciosCobrados from "../../components/Servicios/ServiciosTable/ServiciosCobrados";
import ServiciosEnFalso from "../../components/Servicios/ServiciosTable/ServiciosEnFalso";
import ServiciosPorFirmar from "../../components/Servicios/ServiciosTable/ServiciosPorFirmar";
import ServiciosDetalle from "../../components/Servicios/ServiciosDetalle/ServiciosDetalle";
import ServiciosCrear from "../../components/Servicios/ServiciosCrear/ServiciosCrear";
import ProgramarServicio from "../../components/Servicios/ServiciosProgramar/ProgramarServicio";
import ServiciosPorPagar from "../../components/Servicios/ServiciosTable/ServiciosPorPagar";
import ServiciosPagados from "../../components/Servicios/ServiciosTable/ServiciosPagados";

import ReportesMain from "../../components/Reportes/ReportesMain";
import BusquedaGeneral from "../../components/Busqueda//BusquedaGeneral/BusquedaGeneral";

import UsuariosTable from "../../components/Catalogos/Usuarios/UsuariosTable/UsuariosTable";
import UsuarioDetalle from "../../components/Catalogos/Usuarios/UsuarioDetalle/UsuarioDetalle";
import UsuarioEdit from "../../components/Catalogos/Usuarios/UsuarioEdit/UsuarioEdit";
import UsuarioCrear from "../../components/Catalogos/Usuarios/UsuariosCrear/UsuarioCrear";
import UsuarioAsignar from "../../components/Catalogos/Usuarios/UsuarioAsignar/UsuarioAsignar";

import ClientesTable from "../../components/Catalogos/Clientes/ClientesTable/ClientesTable";
import ClienteDetalle from "../../components/Catalogos/Clientes/ClienteDetalle/ClienteDetalle";
import ClienteEdit from "../../components/Catalogos/Clientes/ClienteEdit/ClienteEdit";
import ClienteCrear from "../../components/Catalogos/Clientes/ClienteCrear/ClienteCrear";

import Unidades from "../Unidades/Unidades";
import UnidadesTable from "../../components/Catalogos/Unidades/UnidadesTable/UnidadesTable";

import TipoServicioTable from "../../components/Catalogos/TipoServicio/TipoServicioTable/TipoServicioTable";
import TipoServicioDetalle from "../../components/Catalogos/TipoServicio/TipoServicioDetalle/TipoServicioDetalle";
import TipoServicioEdit from "../../components/Catalogos/TipoServicio/TipoServicioEdit/TipoServicioEdit";
import TipoServicioCrear from "../../components/Catalogos/TipoServicio/TipoServicioCrear/TipoServicioCrear";
import TipoServicioAsignarPrueba from "../../components/Catalogos/TipoServicio/TipoServicioAsignarPrueba/TipoServicioAsignarPrueba";

import MarcaUnidadTable from "../../components/Catalogos/MarcaUnidad/MarcaUnidadTable/MarcaUnidadTable";
import MarcaUnidadDetalle from "../../components/Catalogos/MarcaUnidad/MarcaUnidadDetalle/MarcaUnidadDetalle";
import MarcaUnidadEdit from "../../components/Catalogos/MarcaUnidad/MarcaUnidadEdit/MarcaUnidadEdit";
import MarcaUnidadCrear from "../../components/Catalogos/MarcaUnidad/MarcaUnidadCrear/MarcaUnidadCrear";

import ModeloGps from "../ModeloGps/ModeloGps";
import ModeloGpsTable from "../../components/Catalogos/ModeloGps/ModeloGpsTable/ModeloGpsTable";
import ModeloGpsDetalle from "../../components/Catalogos/ModeloGps/ModeloGpsDetalle/ModeloGpsDetalle";
import ModeloGpsEdit from "../../components/Catalogos/ModeloGps/ModeloGpsEdit/ModeloGpsEdit";
import ModeloGpsCrear from "../../components/Catalogos/ModeloGps/ModeloGpsCrear/ModeloGpsCrear";

import Accesorios from "../Accesorios/Accesorios";
import AccesoriosTable from "../../components/Catalogos/Accesorios/AccesoriosTable/AccesoriosTable";
import AccesoriosDetalle from "../../components/Catalogos/Accesorios/AccesoriosDetalle/AccesoriosDetalle";
import AccesoriosEdit from "../../components/Catalogos/Accesorios/AccesoriosEdit/AccesoriosEdit";
import AccesoriosCrear from "../../components/Catalogos/Accesorios/AccesoriosCrear/AccesoriosCrear";

import Funcion from "../Funcion/Funcion";
import FuncionTable from "../../components/Catalogos/Funcion/FuncionTable/FuncionTable";
import FuncionDetalle from "../../components/Catalogos/Funcion/FuncionDetalle/FuncionDetalle";
import FuncionEdit from "../../components/Catalogos/Funcion/FuncionEdit/FuncionEdit";
import FuncionCrear from "../../components/Catalogos/Funcion/FuncionCrear/FuncionCrear";

import Prueba from "../Prueba/Prueba";
import PruebaTable from "../../components/Catalogos/Prueba/PruebaTable/PruebaTable";
import PruebaDetalle from "../../components/Catalogos/Prueba/PruebaDetalle/PruebaDetalle";
import PruebaEdit from "../../components/Catalogos/Prueba/PruebaEdit/PruebaEdit";
import PruebaCrear from "../../components/Catalogos/Prueba/PruebaCrear/PruebaCrear";

import Verificacion from "../Verificacion/Verificacion";
import VerificacionTable from "../../components/Catalogos/Verificacion/VerificacionTable/VerificacionTable";
import VerificacionDetalle from "../../components/Catalogos/Verificacion/VerificacionDetalle/VerificacionDetalle";
import VerificacionEdit from "../../components/Catalogos/Verificacion/VerificacionEdit/VerificacionEdit";
import VerificacionCrear from "../../components/Catalogos/Verificacion/VerificacionCrear/VerificacionCrear";

import PersonaPrueba from "../PersonaPrueba/PersonaPrueba";
import PersonaPruebaTable from "../../components/Catalogos/PersonaPrueba/PersonaPruebaTable/PersonaPruebaTable";
import PersonaPruebaCrear from "../../components/Catalogos/PersonaPrueba/PersonaPruebaCrear/PersonaPruebaCrear";
import PersonaPruebaEdit from "../../components/Catalogos/PersonaPrueba/PersonaPruebaEdit/PersonaPruebaEdit";

import Roles from "../Roles/Roles";
import RolesTable from "../../components/Catalogos/Roles/RolesTable/RolesTable";
import RolesCrear from "../../components/Catalogos/Roles/RolesCrear/RolesCrear";
import RolesEdit from "../../components/Catalogos/Roles/RolesEdit/RolesEdit";
import RolesAsignar from "../../components/Catalogos/Roles/RolesAsignar/RolesAsignar";

import Permisos from "../Permisos/Permisos";
import PermisosTable from "../../components/Catalogos/Permisos/PermisosTable/PermisosTable";
import PermisosCrear from "../../components/Catalogos/Permisos/PermisosCrear/PermisosCrear";
import PermisosEdit from "../../components/Catalogos/Permisos/PermisosEdit/PermisosEdit";

import Plataformas from "../Plataformas/Plataformas";
import PlataformasTable from "../../components/Catalogos/Plataformas/PlataformasTable/PlataformasTable";
import PlataformasCrear from "../../components/Catalogos/Plataformas/PlataformasCrear/PlataformasCrear";
import PlataformasEdit from "../../components/Catalogos/Plataformas/PlataformasEdit/PlataformasEdit";
import PlataformasAsignar from "../../components/Catalogos/Plataformas/PlataformasAsignar/PlataformasAsignar";

import { AvisoPrivacidadScreen } from "../AvisoPrivacidadScreen/AvisoPrivacidadScreen";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

//Estilos
import "./App.css";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import ServicioEdit from "../../components/Servicios/ServicioEdit/ServicioEdit";
import Centrales from "../Centrales/Centrales";
import CentralTable from "../../components/Catalogos/Centrales/CentralTable/CentralTable";
import CentralCrear from "../../components/Catalogos/Centrales/CentralCrear/CentralCrear";
import CentralEdit from "../../components/Catalogos/Centrales/CentralEdit/CentralEdit";

/**
 * Screen App
 * @description: Es la pantalla que cubre a la App y que tiene definidos
 * los routes de navegación.
 * @date 14/11/2022.
 * @returns JSX del Screen.
 */

const App = () => {
  const authContext = useContext(AuthContext);
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Routes>
          {!authContext.isLoggedIn && (
            <>
              <Route path="/" element={<Login />} />
              <Route
                path="/main/aviso_privacidad"
                element={<AvisoPrivacidadScreen />}
              />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
          {authContext.isLoggedIn && (
            <>
              <Route path="*" element={<Navigate to="/main" />} />
              <Route path="/main" element={<Main />}>
                <Route index element={<Home />} />
                <Route path="aviso_privacidad" element={<Servicios />}>
                  <Route index element={<AvisoPrivacidadScreen />} />
                </Route>
                <Route path="servicios" element={<Servicios />}>
                  <Route path="capturados" element={<ServiciosCapturados />} />
                  <Route
                    path="programados"
                    element={<ServiciosProgramados />}
                  />
                  <Route path="atendidos" element={<ServiciosAtendidos />} />
                  <Route
                    path="cobrar"
                    element={
                      <ProtectedRoute
                        redirectPath="/home"
                        isAllowed={authContext.tokenCan("Cobro:store")}
                      >
                        <ServiciosPorCobrar />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="cobrados"
                    element={
                      <ProtectedRoute
                        redirectPath="/home"
                        isAllowed={authContext.tokenCan("Cobro:store")}
                      >
                        <ServiciosCobrados />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="pagar"
                    element={
                      <ProtectedRoute
                        redirectPath="/home"
                        isAllowed={authContext.tokenCan("Pago:store")}
                      >
                        <ServiciosPorPagar />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="pagados"
                    element={
                      <ProtectedRoute
                        redirectPath="/home"
                        isAllowed={authContext.tokenCan("Pago:store")}
                      >
                        <ServiciosPagados />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="details/:idActividad"
                    element={<ServiciosDetalle />}
                  />
                  <Route
                    path="crear"
                    element={
                      <ProtectedRoute
                        redirectPath="/home"
                        isAllowed={authContext.tokenCan("Servicio:store")}
                      >
                        <ServiciosCrear />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="programar/:idActividad"
                    element={
                      <ProtectedRoute
                        redirectPath="/home"
                        isAllowed={authContext.tokenCan("Servicio:update")}
                      >
                        <ProgramarServicio />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="edit/:idActividad"
                    element={
                      <ProtectedRoute
                        redirectPath="/home"
                        isAllowed={authContext.tokenCan("Servicio:update")}
                      >
                        <ServicioEdit />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="en_falso" element={<ServiciosEnFalso />} />
                  <Route path="por_firmar" element={<ServiciosPorFirmar />} />
                </Route>
                <Route path="reportes" element={<Reportes />}>
                  <Route index element={<ReportesMain />} />
                </Route>
                <Route path="busqueda" element={<Busqueda />}>
                  <Route index element={<BusquedaGeneral />} />
                </Route>
                <Route path="catalogos" element={<Catalogos />}>
                  <Route path="usuarios" element={<Usuarios />}>
                    <Route index element={<UsuariosTable />} />
                    <Route
                      path="details/:idUsuario"
                      element={<UsuarioDetalle />}
                    />
                    <Route
                      path="edit/:idUsuario"
                      element={
                        <ProtectedRoute
                          redirectPath="/home"
                          isAllowed={authContext.tokenCan("Usuario:update")}
                        >
                          <UsuarioEdit />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="crear"
                      element={
                        <ProtectedRoute
                          redirectPath="/home"
                          isAllowed={authContext.tokenCan("Usuario:store")}
                        >
                          <UsuarioCrear />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="asignar/:idUsuario"
                      element={
                        <ProtectedRoute
                          redirectPath="/home"
                          isAllowed={authContext.tokenCan("Usuario:assign")}
                        >
                          <UsuarioAsignar />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                  <Route path="clientes" element={<Clientes />}>
                    <Route index element={<ClientesTable />} />
                    <Route
                      path="details/:idCliente"
                      element={<ClienteDetalle />}
                    />
                    <Route path="edit/:idCliente" element={<ClienteEdit />} />
                    <Route path="crear" element={<ClienteCrear />} />
                  </Route>
                  <Route path="centrales" element={<Centrales />}>
                    <Route index element={<CentralTable />} />
                    <Route path="edit/:idCentral" element={<CentralEdit />} />
                    <Route path="crear" element={<CentralCrear />} />
                  </Route>
                  <Route path="unidades" element={<Unidades />}>
                    <Route
                      path="listar/:idCliente"
                      element={<UnidadesTable />}
                    />
                  </Route>
                  <Route path="tipoServicio" element={<TipoServicio />}>
                    <Route index element={<TipoServicioTable />} />
                    <Route
                      path="details/:idTipoServicio"
                      element={<TipoServicioDetalle />}
                    />
                    <Route
                      path="edit/:idTipoServicio"
                      element={<TipoServicioEdit />}
                    />
                    <Route path="crear" element={<TipoServicioCrear />} />
                    <Route
                      path="asignarPrueba/:idTipoServicio"
                      element={<TipoServicioAsignarPrueba />}
                    />
                  </Route>
                  <Route path="marcaUnidad" element={<MarcaUnidad />}>
                    <Route index element={<MarcaUnidadTable />} />
                    <Route
                      path="details/:idMarcaUnidad"
                      element={<MarcaUnidadDetalle />}
                    />
                    <Route
                      path="edit/:idMarcaUnidad"
                      element={<MarcaUnidadEdit />}
                    />
                    <Route path="crear" element={<MarcaUnidadCrear />} />
                  </Route>
                  <Route path="modeloGps" element={<ModeloGps />}>
                    <Route index element={<ModeloGpsTable />} />
                    <Route
                      path="details/:idModeloGps"
                      element={<ModeloGpsDetalle />}
                    />
                    <Route
                      path="edit/:idModeloGps"
                      element={<ModeloGpsEdit />}
                    />
                    <Route path="crear" element={<ModeloGpsCrear />} />
                  </Route>
                  <Route path="accesorios" element={<Accesorios />}>
                    <Route index element={<AccesoriosTable />} />
                    <Route
                      path="details/:idAccesorio"
                      element={<AccesoriosDetalle />}
                    />
                    <Route
                      path="edit/:idAccesorio"
                      element={<AccesoriosEdit />}
                    />
                    <Route path="crear" element={<AccesoriosCrear />} />
                  </Route>
                  <Route path="funcion" element={<Funcion />}>
                    <Route index element={<FuncionTable />} />
                    <Route
                      path="details/:idFuncion"
                      element={<FuncionDetalle />}
                    />
                    <Route path="edit/:idFuncion" element={<FuncionEdit />} />
                    <Route path="crear" element={<FuncionCrear />} />
                  </Route>
                  <Route path="prueba" element={<Prueba />}>
                    <Route index element={<PruebaTable />} />
                    <Route
                      path="details/:idPrueba"
                      element={<PruebaDetalle />}
                    />
                    <Route path="edit/:idPrueba" element={<PruebaEdit />} />
                    <Route path="crear" element={<PruebaCrear />} />
                  </Route>
                  <Route path="verificacion" element={<Verificacion />}>
                    <Route index element={<VerificacionTable />} />
                    <Route
                      path="details/:idVerificacion"
                      element={<VerificacionDetalle />}
                    />
                    <Route
                      path="edit/:idVerificacion"
                      element={<VerificacionEdit />}
                    />
                    <Route path="crear" element={<VerificacionCrear />} />
                  </Route>
                  <Route path="personaPrueba" element={<PersonaPrueba />}>
                    <Route index element={<PersonaPruebaTable />} />
                    <Route path="crear" element={<PersonaPruebaCrear />} />
                    <Route
                      path="edit/:idPersonaPrueba"
                      element={<PersonaPruebaEdit />}
                    />
                  </Route>
                  <Route path="roles" element={<Roles />}>
                    <Route index element={<RolesTable />} />
                    <Route path="crear" element={<RolesCrear />} />
                    <Route path="edit/:idRol" element={<RolesEdit />} />
                    <Route path="asignar/:idRol" element={<RolesAsignar />} />
                  </Route>
                  <Route path="permisos" element={<Permisos />}>
                    <Route index element={<PermisosTable />} />
                    <Route path="crear" element={<PermisosCrear />} />
                    <Route path="edit/:idPermiso" element={<PermisosEdit />} />
                  </Route>
                  <Route path="plataformas" element={<Plataformas />}>
                    <Route index element={<PlataformasTable />} />
                    <Route path="crear" element={<PlataformasCrear />} />
                    <Route
                      path="edit/:idPlataforma"
                      element={<PlataformasEdit />}
                    />
                    <Route
                      path="asignar/:idPlataforma"
                      element={<PlataformasAsignar />}
                    />
                  </Route>
                </Route>
              </Route>
            </>
          )}
          {/* <Route path="*" element={<Navigate to="/" />} /> */}
        </Routes>
        <ToastContainer limit={1} />
        {/* <div className="footer">
        This footer will always be positioned at the bottom of the page, but{" "}
        <strong>not fixed</strong>.
      </div> */}
      </div>
    </QueryClientProvider>
  );
};

export default App;
