//librerías
import { useCallback, useMemo, useState } from "react";
import {
  faCheckCircle,
  faFileUpload,
  faHandshake,
  faMoneyBill,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import { Button, Container, Form, Spinner } from "react-bootstrap";

//custom hooks
import useFetch from "../../../hooks/useFetch";

//componentes
import PagoModal from "../../Formularios/PagoModal/PagoModal";
import DropFilePDF from "../../Formularios/DropFilePDF/DropFilePDF";
import CustomTable from "../../Formularios/CustomTable/CustomTable";

//types
import { ServicioAtendido } from "../../../types/ServiciosAtendidos";
import { toast } from "react-toastify";
import { useGetData } from "../../../hooks/useGetData";

//estilos
const classes = require("./ServiciosTable.module.css").default;

/**
 * ServiciosPorCobrar Component
 * @description: Componente que muestra el paginado de los servicios que no se han cobrado.
 * @date 06/03/2023.
 * @param Props Ninguno
 * @returns JSX de la tabla de servicios por cobrar.
 */

const ServiciosPorPagar = () => {
  const { sendRequest } = useFetch();
  //const [actividades, setActividades] = useState<ActividadIndex[]>();
  const [actividadActual, setActividadActual] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDropFile, setShowDropFile] = useState<boolean>(false);
  const [facturas, setFacturas] = useState<number[]>([]);

  const {
    data: actividades,
    isLoading,
    refetch,
  } = useGetData(
    "/actividad?ultima_etapa[gte]=3&onlyPagoNull=true&includeAll=true",
    "actividadesUnidadTipo"
  );

  const SetAsGarantia = useCallback(
    (idActividad: number) => {
      if (
        window.confirm(
          "¿Está seguro que desea establecer este servicio como garantía?"
        ) === true
      ) {
        sendRequest(
          {
            url: `/pago`,
            method: "POST",
            body: {
              servicio: 0,
              traslado: 0,
              actividad_id: idActividad,
              tipo_pago_id: 2,
            },
          },
          (variable: any) => {
            toast.success("Se registró la garantía con éxito", {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            refetch();
          }
        );
      }
    },
    [sendRequest, refetch]
  );

  //Definicion de las columnas de la tabla
  const columns = useMemo<ColumnDef<ServicioAtendido>[]>(
    () => [
      {
        header: " ",
        cell: (info) => (
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              onChange={(value) => {
                if (value.currentTarget.checked) {
                  if (facturas.length >= 7) {
                    toast.error(
                      "No es posible agregar una factura a mas de 7 servicios al mismo tiempo",
                      {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      }
                    );
                    return;
                  }
                  setFacturas((prev) => [...prev, info.row.original.id]);
                } else {
                  setFacturas((prev) =>
                    prev.filter((element) => element !== info.row.original.id)
                  );
                }
              }}
              checked={
                facturas.find((fact) => fact === info.row.original.id)
                  ? true
                  : false
              }
            />
          </Form.Group>
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "Órden de servicio", // nombre que aparecera en el encabezado de la tabla
        accessorKey: "id", //identificador de la columna
        cell: (info) => info.getValue(), // valor que aparecera en esa columna
        footer: (props) => props.column.id,
      },
      {
        header: "Fecha y hora de la cita",
        accessorKey: "fecha",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Folio",
        accessorFn: (row) => (row.servicio.folio ? row.servicio.folio : ""),
        id: "Folio",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Razón social",
        accessorFn: (row) => row.servicio.cliente.razon_social,
        id: "razon_social",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Técnico",
        accessorFn: (row) =>
          `${row.usuario.nombre} ${row.usuario.apellido_paterno} ${row.usuario.apellido_materno}`,
        id: "tecnico",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Tipo de servicio",
        accessorFn: (row) => row.tipo_servicio.nombre,
        id: "tipo_servicio",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Económico",
        accessorFn: (row) => (row.unidad.economico ? row.unidad.economico : ""),
        id: "economico",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Placas",
        accessorFn: (row) => (row.unidad.placa ? row.unidad.placa : ""),
        id: "placa",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Descripción",
        accessorFn: (row) =>
          row.descripcion_servicio ? row.descripcion_servicio : "",
        id: "descripcion",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            {/** Boton de despliegue del modal Cobrar servicio*/}
            <Button
              title="Pagar este servicio"
              style={{ backgroundColor: "#2874A6", borderColor: "white" }}
              onClick={() => {
                setShowModal(true);
                setActividadActual(info.row.original.id);
              }}
            >
              <FontAwesomeIcon icon={faMoneyBill} />
            </Button>
            {/** Boton de despliegue del modal No cobrar servicio */}
            <Button
              title="Establecer como Garantía"
              style={{ backgroundColor: "#21618C", borderColor: "white" }}
              onClick={() => SetAsGarantia(info.row.original.id)}
            >
              <FontAwesomeIcon icon={faHandshake} />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "Factura",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <FontAwesomeIcon
              icon={info.row.original.factura ? faCheckCircle : faTimesCircle}
              style={{
                color: info.row.original.factura ? "#075714" : "#A60606",
              }}
            />
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [SetAsGarantia, facturas]
  );

  // Mientras se cargan las actividades se muestra un spinner
  if (isLoading) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container style={{ paddingBottom: "1rem" }}>
      <h1>Pagar a proveedores</h1>
      <div className="d-flex justify-content-between">
        <div>
          {facturas.map((factura) => (
            <Button
              variant="light"
              onClick={() =>
                setFacturas((prev) =>
                  prev.filter((element) => element !== factura)
                )
              }
              key={`fact-${factura}`}
            >
              <span className="ms-1">{factura} </span>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ marginLeft: 5, fontSize: 16, color: "#930404" }}
              />
            </Button>
          ))}
        </div>
        <div>
          <Button
            title="Agregar una nueva factura"
            style={{
              backgroundColor: "#21618C",
              borderColor: "white",
              marginTop: "10px",
            }}
            onClick={() => setShowDropFile(true)}
            disabled={facturas.length === 0 ? true : false}
          >
            <FontAwesomeIcon icon={faFileUpload} /> Subir factura
          </Button>
        </div>
      </div>
      {<div style={{ display: "flex", justifyContent: "flex-end" }}></div>}
      {/** Tabla de actividades */}
      <CustomTable
        {...{
          data: actividades,
          columns,
        }}
      />
      {/** Modal de cobro */}
      {showModal && (
        <PagoModal
          actividadId={actividadActual}
          showModal={showModal}
          handleCloseModal={() => setShowModal(false)}
          refetch={refetch}
        />
      )}
      {/** Modal de no cobro */}
      {showDropFile && (
        <DropFilePDF
          showModal={showDropFile}
          handleCloseModal={() => setShowDropFile(false)}
          facturas={facturas}
          refetch={refetch}
        />
      )}
    </Container>
  );
};

export default ServiciosPorPagar;
