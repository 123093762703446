//Librerías
import { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

//Componentes
import useFetch from "../../../hooks/useFetch";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { useGetData } from "../../../hooks/useGetData";

//interfaces
import { Marca, Unidad } from "../../../interfaces/ServiciosInterface";
import { TipoServicio } from "../../../interfaces/ActividadIndexInterface";
import { ActividadShow } from "../../../interfaces/ActividadShowInterface";
import { SucursalInterface } from "../../../interfaces/SucursalInterface";
import { EstadoInterface } from "../../../interfaces/EstadoInterface";

//estilos
const classes = require("./ServicioEdit.module.css").default;

interface Contacto {
  id: number;
  nombre: string;
  correo: string;
  servicio_id: number;
  telefono_movil: string;
  telefono_fijo: string;
}

/**
 * ServicioEdit Component
 * @description: Componente que permite editar un servicio.
 * @date 17/12/2022.
 * @param Props No aplica
 * @returns JSX formulario del servicio.
 */

const ServicioEdit = () => {
  const { idActividad } = useParams();
  const { sendRequest } = useFetch();
  const [actividad, setActividad] = useState<ActividadShow>();
  const navigate = useNavigate();
  const [contacto, setContacto] = useState<Contacto[]>([]);
  const [unidad, setUnidad] = useState<Unidad>();
  const [marcasUnidades, setMarcasUnidades] = useState<Marca[]>([]);
  const [tiposServicios, setTiposServicios] = useState<TipoServicio[]>([]);
  const [tipoServicio, setTiposServicio] = useState<number>();
  const [estado, setEstado] = useState<EstadoInterface>();
  const [sucursal, setSucursal] = useState<SucursalInterface>();

  const { data: estados } = useGetData("/estado", "estado");
  const { data: sucursales } = useGetData("/sucursal", "sucursal");

  let currentContacto: Contacto[] = [];
  let bodyPost = {};

  //Elementos del formulario
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fecha: "",
      hora: "",
      domicilio_cliente: "",
      latitud_cliente: "",
      longitud_cliente: "",
      notas_cliente: "",
      folio: "",
      cliente_id: "",
      ultima_etapa: 1,
    },
  });

  //Función que trata los datos de la actividad recibida.
  const transformData = useCallback(
    (variable: any) => {
      setActividad(variable.data);
      setContacto(variable.data.servicio.contacto);
      setUnidad(variable.data.unidad);
      setTiposServicio(variable.data.tipo_servicio.id);
      setEstado(variable.data.servicio.estado);
      setSucursal(variable.data.servicio.sucursal);
      reset({
        domicilio_cliente: variable.data.servicio.domicilio_cliente,
        latitud_cliente: variable.data.servicio.latitud_cliente,
        longitud_cliente: variable.data.servicio.longitud_cliente,
        notas_cliente: variable.data.servicio.notas_cliente,
        folio: variable.data.servicio.folio,
        fecha: variable.data.fecha ? variable.data.fecha?.substring(0, 10) : "",
        hora: variable.data.fecha
          ? variable.data.servicio.fecha?.substring(11, 16)
          : "",
        cliente_id: variable.data.servicio.cliente.id,
        ultima_etapa: variable.data.ultima_etapa,
      });
    },
    [reset]
  );

  //Trata las marca de unidades
  const transformDataMarcaUnidad = useCallback((variable: any) => {
    setMarcasUnidades(variable.data);
  }, []);

  //Trae a los tuipos de servicio
  const transformDataTipoServicio = useCallback((variable: any) => {
    setTiposServicios(variable.data);
  }, []);

  //Se recibe la actividad a editar.
  useEffect(() => {
    sendRequest(
      {
        url: `/actividad/${idActividad}?includeAll=true`,
      },
      transformData
    );
  }, [idActividad, sendRequest, transformData]);

  //Se recibe las marcas de unidades.
  useEffect(() => {
    sendRequest(
      {
        url: `/marca_unidad`,
      },
      transformDataMarcaUnidad
    );
  }, [sendRequest, transformDataMarcaUnidad]);

  //Se reciben los tipos de servicio
  useEffect(() => {
    sendRequest(
      {
        url: `/tipo_servicio?estatus[eq]=1`,
      },
      transformDataTipoServicio
    );
  }, [sendRequest, transformDataTipoServicio]);

  //Función que es llamada al terminar el formulario.
  const editServicio = (data: any) => {
    bodyPost = {
      actividad_id: actividad?.id,
      ...data,
      contacto: [...contacto],
      unidad: unidad,
      tipo_servicio: tipoServicio,
      fecha: data.fecha ? `${data.fecha} ${data.hora}` : null,
      editar: true,
      estado_id: estado?.id,
      sucursal_id: sucursal?.id,
    };
    sendRequest(
      {
        url: `/servicio/${actividad?.servicio.id}`,
        method: "PATCH",
        body: bodyPost,
      },
      (variable: any) => {
        toast.success("Se editó el servicio correctamente", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate(-1);
      }
    );
  };

  //mientras se carga el detalle se muestra un spinner
  if (!actividad) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h1>Editar el servicio {idActividad}</h1>
      <h2 className={classes.subtitulo}>Datos generales </h2>
      <Form onSubmit={handleSubmit(editServicio)}>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Fecha de servicio</Form.Label>
            <Form.Control type="date" {...register("fecha")} disabled />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Hora de servicio</Form.Label>
            <Form.Control {...register("hora")} type="time" disabled />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Folio</Form.Label>
            <Form.Control {...register("folio")} />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Razón Social</Form.Label>
            <Form.Control
              value={actividad.servicio.cliente.razon_social as string}
              disabled
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Nombre comercial</Form.Label>
            <Form.Control
              value={actividad.servicio.cliente.nombre_comercial as string}
              disabled
            />
          </Form.Group>
        </Row>

        <h2 className={classes.subtitulo}>Datos del cliente </h2>
        <Row className="mb-3">
          {actividad.servicio.cliente.id === 455 && (
            <Form.Group as={Col}>
              <Form.Label>Sucursal</Form.Label>
              <Select
                placeholder="Selecciona una opción"
                options={sucursales?.map((sucursal: SucursalInterface) => {
                  return {
                    value: sucursal.id,
                    label: sucursal.nombre,
                    direccion: sucursal.direccion,
                  };
                })}
                defaultValue={{
                  value: sucursal?.id,
                  label: sucursal?.nombre,
                  direccion: sucursal?.direccion,
                }}
                onChange={(sucursal: any) => {
                  setSucursal({
                    id: sucursal.value,
                    nombre: sucursal.label,
                    direccion: sucursal.direccion,
                  });
                  setValue("domicilio_cliente", sucursal.direccion);
                }}
              />
            </Form.Group>
          )}

          <Form.Group as={Col}>
            <Form.Label>Domicilio</Form.Label>
            <Form.Control
              {...register("domicilio_cliente", {
                maxLength: 200,
                required: true,
              })}
            />
            {errors.domicilio_cliente && (
              <Form.Text style={{ color: "red" }}>
                El domicilio no puede ser mayor a 200 caracteres y es
                obligatorio.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Localidad</Form.Label>
            <Select
              placeholder="Selecciona una opción"
              options={estados?.map((estado: EstadoInterface) => {
                return {
                  value: estado.id,
                  label: estado.nombre,
                };
              })}
              defaultValue={{ value: estado?.id, label: estado?.nombre }}
              onChange={(estado: any) =>
                setEstado({ id: estado.value, nombre: estado.label })
              }
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Latitud</Form.Label>
            <Form.Control
              type="number"
              step={0.00000000001}
              min={12.425848}
              max={33.394759}
              {...register("latitud_cliente", {
                maxLength: 50,
                required: true,
                min: 12.425848,
                max: 33.394759,
              })}
            />
            {errors.latitud_cliente && (
              <Form.Text style={{ color: "red" }}>
                Las coordenadas no puede ser mayor a 50 caracteres y es
                obligatorio.
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Longitud</Form.Label>
            <Form.Control
              type="number"
              step={0.00000000001}
              min={-118.959961}
              max={-85.825195}
              {...register("longitud_cliente", {
                maxLength: 50,
                required: true,
                min: -118.959961,
                max: -85.825195,
              })}
            />
            {errors.longitud_cliente && (
              <Form.Text style={{ color: "red" }}>
                Las coordenadas no puede ser mayor a 50 caracteres y es
                obligatorio.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Observaciones</Form.Label>
            <Form.Control {...register("notas_cliente")} as="textarea" />
          </Form.Group>
        </Row>
        {contacto.length > 0 && (
          <Alert variant="secondary">
            <Alert.Heading>Datos de contacto</Alert.Heading>
            <>
              {contacto.map((contact: any, index) => {
                return (
                  <Row key={contact.id} className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>Nombre del contacto</Form.Label>
                      <Form.Control
                        value={contact.nombre as string}
                        onChange={(event) => {
                          currentContacto = contacto;
                          currentContacto[index].nombre = event.target.value;
                          setContacto([...currentContacto]);
                        }}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        value={contact.correo as string}
                        onChange={(event) => {
                          currentContacto = contacto;
                          currentContacto[index].correo = event.target.value;
                          setContacto([...currentContacto]);
                        }}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Teléfono móvil</Form.Label>
                      <Form.Control
                        value={contact.telefono_movil as string}
                        onChange={(event) => {
                          currentContacto = contacto;
                          currentContacto[index].telefono_movil =
                            event.target.value;
                          setContacto([...currentContacto]);
                        }}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Teléfono fijo</Form.Label>
                      <Form.Control
                        value={contact.telefono_fijo as string}
                        onChange={(event) => {
                          currentContacto = contacto;
                          currentContacto[index].telefono_fijo =
                            event.target.value;
                          setContacto([...currentContacto]);
                        }}
                      />
                    </Form.Group>
                  </Row>
                );
              })}
            </>
          </Alert>
        )}

        {unidad && (
          <div>
            <h2 className={classes.subtitulo}>Datos de la unidad </h2>
            <Row key={actividad.unidad.id} className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Tipo Servicio</Form.Label>
                <Form.Select
                  value={tipoServicio}
                  onChange={(event) => setTiposServicio(+event.target.value)}
                >
                  {tiposServicios.map((tiposServicio) => (
                    <option key={tiposServicio.nombre} value={tiposServicio.id}>
                      {tiposServicio.nombre}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Marca</Form.Label>
                <Form.Select
                  value={
                    unidad.marca_unidad_id
                      ? unidad.marca_unidad_id
                      : unidad.marca_unidad?.id
                  }
                  onChange={(event) => {
                    setUnidad((prev: any) => {
                      return { ...prev, marca_unidad_id: +event.target.value };
                    });
                  }}
                >
                  {marcasUnidades.map((marcaUnidad) => (
                    <option key={marcaUnidad.nombre} value={marcaUnidad.id}>
                      {marcaUnidad.nombre}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Submarca</Form.Label>
                <Form.Control
                  value={unidad.submarca as string}
                  onChange={(event) => {
                    setUnidad((prev: any) => {
                      return { ...prev, submarca: event.target.value };
                    });
                  }}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Modelo</Form.Label>
                <Form.Control
                  value={unidad.modelo as string}
                  onChange={(event) => {
                    setUnidad((prev: any) => {
                      return { ...prev, modelo: event.target.value };
                    });
                  }}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Económico</Form.Label>
                <Form.Control
                  value={unidad.economico as string}
                  onChange={(event) => {
                    setUnidad((prev: any) => {
                      return { ...prev, economico: event.target.value };
                    });
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Vin</Form.Label>
                <Form.Control
                  value={unidad.vin as string}
                  onChange={(event) => {
                    setUnidad((prev: any) => {
                      return { ...prev, vin: event.target.value };
                    });
                  }}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Placas</Form.Label>
                <Form.Control
                  value={unidad.placa as string}
                  onChange={(event) => {
                    setUnidad((prev: any) => {
                      return { ...prev, placa: event.target.value };
                    });
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Color</Form.Label>
                <Form.Control
                  value={unidad.color as string}
                  onChange={(event) => {
                    setUnidad((prev: any) => {
                      return { ...prev, color: event.target.value };
                    });
                  }}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Voltaje</Form.Label>
                <Form.Control
                  type={"number"}
                  value={unidad.voltaje as string}
                  onChange={(event) => {
                    setUnidad((prev: any) => {
                      return { ...prev, voltaje: +event.target.value };
                    });
                  }}
                />
              </Form.Group>
            </Row>
          </div>
        )}

        <Button
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
            marginTop: "10px",
          }}
          type="submit"
        >
          Guardar
        </Button>
      </Form>
    </Container>
  );
};

export default ServicioEdit;
