//librerias
import { useContext } from "react";
import { Link, Outlet } from "react-router-dom";

//context
import { AuthContext } from "../../context/AuthContext";

//estilos
const classes = require("./Catalogos.module.css").default;

const Catalogos = () => {
  const authContext = useContext(AuthContext);
  return (
    <main className="main">
      <aside className={classes.sidebar}>
        <nav className={classes.nav}>
          <ul className={classes.listado}>
            <li className={`${classes.item}`}>
              <Link className={classes.a} to="usuarios">
                Usuarios
              </Link>
            </li>
            <li className={`${classes.item}`}>
              <Link className={classes.a} to="clientes">
                Clientes
              </Link>
            </li>
            <li className={`${classes.item}`}>
              <Link className={classes.a} to="centrales">
                Centrales
              </Link>
            </li>
            <li className={`${classes.item}`}>
              <Link className={classes.a} to="tipoServicio">
                Tipos de Servicio
              </Link>
            </li>
            <li className={`${classes.item}`}>
              <Link className={classes.a} to="marcaUnidad">
                Marcas de vehículos
              </Link>
            </li>
            <li className={`${classes.item}`}>
              <Link className={classes.a} to="modeloGps">
                Modelos de GPS
              </Link>
            </li>
            <li className={`${classes.item}`}>
              <Link className={classes.a} to="accesorios">
                Accesorios
              </Link>
            </li>
            <li className={`${classes.item}`}>
              <Link className={classes.a} to="funcion">
                Funciones
              </Link>
            </li>
            <li className={`${classes.item}`}>
              <Link className={classes.a} to="prueba">
                Pruebas
              </Link>
            </li>
            <li className={`${classes.item}`}>
              <Link className={classes.a} to="verificacion">
                Verificaciones
              </Link>
            </li>
            <li className={`${classes.item}`}>
              <Link className={classes.a} to="personaPrueba">
                Persona en pruebas
              </Link>
            </li>
            {authContext.tokenCan("Rol:show") && (
              <li className={`${classes.item}`}>
                <Link className={classes.a} to="roles">
                  Roles
                </Link>
              </li>
            )}
            {authContext.tokenCan("Permiso:show") && (
              <li className={`${classes.item}`}>
                <Link className={classes.a} to="permisos">
                  Permisos
                </Link>
              </li>
            )}
            {authContext.tokenCan("Plataforma:show") && (
              <li className={`${classes.item}`}>
                <Link className={classes.a} to="plataformas">
                  Plataformas
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </aside>

      <section className={classes.contentRight}>
        <div className={classes.container}>
          <Outlet />
        </div>
      </section>
    </main>
  );
};

export default Catalogos;
