/** Custom Hook 'useDeleteActividad' 
* Descripción: Para eliminar un servicio.
  Parámetros: 
    @setData Funcion de useState que permite cambiar el estado de las actividades.
  Return values:
    @1 Funcion que se debe invocar para poder eliminar la actividad.
*/

import { useCallback } from "react";
import useFetch from "./useFetch";
import { toast } from "react-toastify";
import { ActividadIndex } from "../interfaces/ActividadIndexInterface";

const useDeleteActividad = (
  setData: React.Dispatch<React.SetStateAction<ActividadIndex[] | undefined>>
) => {
  const { sendRequest } = useFetch();

  const eliminarServicio = useCallback(
    (idActividad: number) => {
      if (
        window.confirm(
          "¿Está seguro que desea eliminar este servicio? No podrá recuperarlo después."
        ) === true
      ) {
        sendRequest(
          {
            url: `/actividad/${idActividad}`,
            method: "DELETE",
          },
          (variable: any) => {
            setData((prev) =>
              prev?.filter((element) => element.id !== idActividad)
            );
            toast.success("Se elimiinó la garantía con éxito", {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        );
      }
    },
    [sendRequest, setData]
  );

  return { eliminarServicio };
};

export default useDeleteActividad;
