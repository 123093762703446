import { useEffect, useState } from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";

//interfaces
import { FuncionInterface } from "../../../../interfaces/FuncionInterface";

//estilos
const classes = require("./FuncionDetalle.module.css").default;

/**
 * FuncionDetalle
 * @description: Componente que permite editar un funcion.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de funcions.
 */

const FuncionDetalle = () => {
  const { idFuncion } = useParams();
  const { sendRequest } = useFetch();
  const [funcion, setFuncion] = useState<FuncionInterface>();

  //Función que trata el detalle de funcion.
  const transformData = (variable: any) => {
    setFuncion(variable.data);
  };

  useEffect(() => {
    sendRequest(
      {
        url: `/funcion/${idFuncion}`,
      },
      transformData
    );
  }, [idFuncion, sendRequest]);

  //mientras se carga el detalle se muestra un spinner
  if (!funcion) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="danger" />
      </div>
    );
  }

  return (
    <Container style={{ marginTop: "20px" }}>
      <h2>Detalle de la funcion {idFuncion} </h2>
      <Card style={{ width: "18rem" }} className={"mx-auto"}>
        <Card.Body>
          <Card.Title>{funcion.nombre}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {`${funcion.nombre}`}
          </Card.Subtitle>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default FuncionDetalle;
