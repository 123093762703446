import { useEffect, useState } from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";

//interfaces
import { MarcaUnidadInterface } from "../../../../interfaces/MarcaUnidadInterface";

//estilos
const classes = require("./MarcaUnidadDetalle.module.css").default;

/**
 * MarcaUnidadDetalle
 * @description: Componente que permite editar un marcaUnidad.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de marcaUnidads.
 */

const MarcaUnidadDetalle = () => {
  const { idMarcaUnidad } = useParams();
  const { sendRequest } = useFetch();
  const [marcaUnidad, setMarcaUnidad] = useState<MarcaUnidadInterface>();

  //Función que trata el detalle de marcaUnidad.
  const transformData = (variable: any) => {
    setMarcaUnidad(variable.data);
  };

  useEffect(() => {
    sendRequest(
      {
        url: `/marca_unidad/${idMarcaUnidad}`,
      },
      transformData
    );
  }, [idMarcaUnidad, sendRequest]);

  //mientras se carga el detalle se muestra un spinner
  if (!marcaUnidad) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="danger" />
      </div>
    );
  }

  return (
    <Container style={{ marginTop: "20px" }}>
      <h2>Detalle del tipo de servicio {idMarcaUnidad} </h2>
      <Card style={{ width: "18rem" }} className={"mx-auto"}>
        <Card.Body>
          <Card.Title>{marcaUnidad.nombre}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {`${marcaUnidad.nombre}`}
          </Card.Subtitle>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default MarcaUnidadDetalle;
