import { useEffect, useState } from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";

//interfaces
import { PruebaInterface } from "../../../../interfaces/PruebaInterface";

//estilos
const classes = require("./PruebaDetalle.module.css").default;

/**
 * PruebaDetalle
 * @description: Componente que permite editar un prueba.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de pruebas.
 */

const PruebaDetalle = () => {
  const { idPrueba } = useParams();
  const { sendRequest } = useFetch();
  const [prueba, setPrueba] = useState<PruebaInterface>();

  //Función que trata el detalle de prueba.
  const transformData = (variable: any) => {
    setPrueba(variable.data);
  };

  useEffect(() => {
    sendRequest(
      {
        url: `/prueba/${idPrueba}`,
      },
      transformData
    );
  }, [idPrueba, sendRequest]);

  //mientras se carga el detalle se muestra un spinner
  if (!prueba) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="danger" />
      </div>
    );
  }

  return (
    <Container style={{ marginTop: "20px" }}>
      <h2>Detalle de la prueba {idPrueba} </h2>
      <Card style={{ width: "18rem" }} className={"mx-auto"}>
        <Card.Body>
          <Card.Title>{prueba.nombre}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {`${prueba.nombre}`}
          </Card.Subtitle>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PruebaDetalle;
