import { useEffect, useState } from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";

//interfaces
import { UsuarioInterface } from "../../../../interfaces/UsuarioInterface";

//estilos
const classes = require("./UsuarioDetalle.module.css").default;

/**
 * UsuarioDetalle
 * @description: Componente que permite editar un usuario.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de usuarios.
 */

const UsuarioDetalle = () => {
  const { idUsuario } = useParams();
  const { sendRequest } = useFetch();
  const [usuario, setUsuario] = useState<UsuarioInterface>();

  //Función que trata el detalle de usuario.
  const transformData = (variable: any) => {
    setUsuario(variable.data);
  };

  useEffect(() => {
    sendRequest(
      {
        url: `/usuario/${idUsuario}`,
      },
      transformData
    );
  }, [idUsuario, sendRequest]);

  //mientras se carga el detalle se muestra un spinner
  if (!usuario) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="danger" />
      </div>
    );
  }

  return (
    <Container style={{ marginTop: "20px" }}>
      <h2>Detalle del usuario {idUsuario} </h2>
      <Card style={{ width: "18rem" }} className={"mx-auto"}>
        <Card.Body>
          <Card.Title>{usuario.nombre}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {usuario.apellido_paterno} {usuario.apellido_materno}
          </Card.Subtitle>
          <Card.Text>Correo electrónico: {usuario.correo}</Card.Text>
          <Card.Text>Estado: {usuario.estado?.nombre}</Card.Text>
          <Card.Text>Municipio: {usuario.municipio}</Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default UsuarioDetalle;
