import { useState, SetStateAction, Dispatch, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Buscar } from "../Buscar/Buscar";
import { UnidadList } from "../UnidadList/UnidadList";
import { useGetData } from "../../../hooks/useGetData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { UnidadInterface } from "../../../interfaces/UnidadInterface";
import { UnidadCrearModal } from "../UnidadCrearModal/UnidadCrearModal";
import {
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";

//Estilos
const classes = require("./Unidades.module.css").default;

interface Props {
  idCliente: number;
  // unidades: UnidadInterface[] | [];
  // setUnidades: Dispatch<SetStateAction<UnidadInterface[]>>;
  setValue: UseFormSetValue<FieldValues>;
  actividad: any[];
  getValues: UseFormGetValues<FieldValues>;
  setActividad: Dispatch<SetStateAction<any[]>>;
}

/**
 * Unidades Component
 * @description: Componente que muestra las unidades de un cliente
 * @date 16/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de clientes.
 */
export const Unidades = ({
  idCliente,
  setValue,
  getValues,
  setActividad,
  actividad,
}: Props) => {
  //useState's
  const [busquedaUnidades, setBusquedaUnidades] = useState<UnidadInterface[]>(
    []
  );
  const [term, setTerm] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);

  //Hooks
  const { data: unidadesList } = useGetData(
    `/unidad?cliente_id[eq]=${idCliente}&includeAll[eq]=true`,
    `unidades${idCliente}`
  );

  //Se actualiza la variable de listado de unidades.
  useEffect(() => {
    setBusquedaUnidades(unidadesList);
  }, [unidadesList]);

  useEffect(() => {
    if (busquedaUnidades && unidadesList) {
      if (term.length === 0) {
        return setBusquedaUnidades(unidadesList);
      }
      const busquedaUnidades = unidadesList.filter(
        (unidad: UnidadInterface) => {
          if (
            (unidad.placa !== null &&
              unidad?.placa?.toLocaleLowerCase().includes(term)) ||
            (unidad.vin !== null &&
              unidad?.vin?.toLocaleLowerCase().includes(term)) ||
            (unidad.economico !== null &&
              unidad?.economico?.toLocaleLowerCase().includes(term))
          ) {
            return unidad;
          }
          return null;
        }
      );
      if (busquedaUnidades) {
        setBusquedaUnidades(busquedaUnidades);
      }
    }
  }, [term, busquedaUnidades, unidadesList]);

  //Funciones
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <>
      <Row>
        <h2 className={classes.subtitulo}>Datos de instalación y unidades </h2>
        <Col xs={4}>
          <Buscar onDebounce={(value) => setTerm(value.toLocaleLowerCase())} />
        </Col>
        <Col xs={2} style={{ padding: "10px" }}>
          <Button
            title="Crear unidad"
            style={{
              backgroundColor: "#5DADE2",
              borderColor: "white",
            }}
            onClick={handleShowModal}
          >
            <FontAwesomeIcon icon={faAdd} />
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <UnidadList
          unidadesList={busquedaUnidades}
          setValue={setValue}
          getValues={getValues}
          setActividad={setActividad}
          actividad={actividad}
          cliente={idCliente}
        />
      </Row>
      {showModal && (
        <UnidadCrearModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          idCliente={idCliente}
        />
      )}
    </>
  );
};
