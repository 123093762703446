import { Outlet } from "react-router-dom";

const Prueba = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default Prueba;
