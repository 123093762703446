import { useEffect, useState } from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";

//interfaces
import { TipoServicioInterface } from "../../../../interfaces/TipoServicioInterface";

//estilos
const classes = require("./TipoServicioDetalle.module.css").default;

/**
 * TipoServicioDetalle
 * @description: Componente que permite editar un tipoServicio.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de tipoServicios.
 */

const TipoServicioDetalle = () => {
  const { idTipoServicio } = useParams();
  const { sendRequest } = useFetch();
  const [tipoServicio, setTipoServicio] = useState<TipoServicioInterface>();

  //Función que trata el detalle de tipoServicio.
  const transformData = (variable: any) => {
    setTipoServicio(variable.data);
  };

  useEffect(() => {
    sendRequest(
      {
        url: `/tipo_servicio/${idTipoServicio}`,
      },
      transformData
    );
  }, [idTipoServicio, sendRequest]);

  //mientras se carga el detalle se muestra un spinner
  if (!tipoServicio) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="danger" />
      </div>
    );
  }

  return (
    <Container style={{ marginTop: "20px" }}>
      <h2>Detalle del tipo de servicio {idTipoServicio} </h2>
      <Card style={{ width: "18rem" }} className={"mx-auto"}>
        <Card.Body>
          <Card.Title>{tipoServicio.nombre}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {`${tipoServicio.nombre}`}
          </Card.Subtitle>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TipoServicioDetalle;
