import { Accordion, Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  Controller,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  useForm,
} from "react-hook-form";
import { UnidadInterface } from "../../../interfaces/UnidadInterface";
import Select, { SingleValue } from "react-select";
import { useGetData } from "../../../hooks/useGetData";
import { TipoServicioInterface } from "../../../interfaces/TipoServicioInterface";
import { Loading } from "../../Loading/Loading";
import { FuncionInterface } from "../../../interfaces/FuncionInterface";
import { Dispatch, SetStateAction, useState } from "react";
import { toast } from "react-toastify";

interface Props {
  unidad: UnidadInterface;
  handleCloseModal: () => void;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  setActividad: Dispatch<SetStateAction<any[]>>;
  actividad: any[];
}

/**
 * UnidadConfigurarModal Component
 * @description: Componente que permite configurar una unidad.
 * @date 16/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de clientes.
 */
export const UnidadConfigurarModal = ({
  unidad,
  handleCloseModal,
  setActividad,
  actividad,
}: Props) => {
  const [isRevision, setIsRevision] = useState<boolean>(true);

  const { data: tiposServicio, isLoading: isLoadingTipoServicio } = useGetData(
    "/tipo_servicio?estatus[eq]=1",
    "tiposServicio"
  );

  const { data: modelosGps, isLoading: isLoadingModeloGps } = useGetData(
    "/modelo_gps",
    "modelosGps"
  );

  const { data: funciones, isLoading: isLoadingFuncion } = useGetData(
    "/funcion",
    "funciones"
  );

  const {
    register,
    control,
    setValue,
    getValues: getFormConfigurar,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    setValue(
      "tipoServicio",
      tiposServicio.find(
        (element: any) => element.id === getFormConfigurar("tipo_servicio_id")
      )
    );
    setValue("unidad", unidad);
    handleCloseModal();
    if (
      actividad !== undefined &&
      actividad.find(
        (element) =>
          element.unidad.id === unidad.id &&
          element.tipo_servicio_id === getFormConfigurar("tipo_servicio_id")
      )
    ) {
      toast.error("Unidad ya cuenta con este tipo de servicio.", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    setActividad((prev) => [...prev, { ...getFormConfigurar() }]);
  };

  if (isLoadingTipoServicio || isLoadingModeloGps || isLoadingFuncion) {
    return <Loading />;
  }

  return (
    <Modal show={true} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Unidad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Tipo de servicio</Form.Label>
            <Controller
              control={control}
              name="tipo_servicio_id"
              rules={{ required: true }}
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  ref={ref}
                  placeholder="Selecciona una opción"
                  isClearable
                  onChange={(value: SingleValue<{ value: any }>) => {
                    onChange(value?.value);
                    setIsRevision(
                      tiposServicio.find(
                        (element: any) => element.id === value?.value
                      ).revision
                    );
                  }}
                  options={tiposServicio?.map(
                    (tipoServicio: TipoServicioInterface) => {
                      return {
                        value: tipoServicio.id,
                        label: tipoServicio.nombre,
                      };
                    }
                  )}
                />
              )}
            />
            {errors.tipo_servicio_id && (
              <Form.Text style={{ color: "red" }}>
                Se requiere elegir un tipo de servicio
              </Form.Text>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Accordion>
            {/* <Accordion.Item eventKey="0">
              <Accordion.Header>Equipo</Accordion.Header>
              <Accordion.Body>
                <Form.Group as={Col}>
                  <Form.Label>Imei</Form.Label>
                  <Form.Control
                    {...register("imei", { value: undefined, maxLength: 50 })}
                    placeholder="Ingresar el IMEI"
                  />
                  {errors.imei && (
                    <Form.Text style={{ color: "red" }}>
                      El IMEI no puede ser mayor a 50 caracteres.
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Modelo del GPS</Form.Label>
                  <Controller
                    control={control}
                    name="modelo_gps_id"
                    rules={{
                      validate: {
                        required: (value) => {
                          if (!value && getFormConfigurar("imei") !== "")
                            return "Requerido";
                          return true;
                        },
                      },
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        ref={ref}
                        placeholder="Selecciona una opción"
                        isClearable
                        onChange={(value: SingleValue<{ value: any }>) =>
                          onChange(value?.value)
                        }
                        options={modelosGps?.map(
                          (modeloGps: ModeloGpsInterface) => {
                            return {
                              value: modeloGps.id,
                              label: modeloGps.nombre,
                            };
                          }
                        )}
                      />
                    )}
                  />
                  {errors.modelo_gps_id && (
                    <Form.Text style={{ color: "red" }}>
                      Se requiere elegir un tipo de servicio
                    </Form.Text>
                  )}
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item> */}
            {getFormConfigurar("tipo_servicio_id") && !isRevision && (
              <Accordion.Item eventKey="1">
                <Accordion.Header>Funciones</Accordion.Header>
                <Accordion.Body>
                  {funciones?.map((funcion: FuncionInterface) => (
                    <Controller
                      control={control}
                      name={`funcion.${funcion.id}.id`}
                      render={({ field: { onChange, ref } }) => (
                        <Form.Check
                          key={`funciones-${unidad.id}-${funcion.id}`}
                          ref={ref}
                          onChange={(value) => {
                            if (value.target.checked) {
                              onChange(value.target.value);
                            }
                          }}
                          type="switch"
                          label={funcion.nombre}
                          value={funcion.id}
                        />
                      )}
                    />
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </Row>
        <Row className="mb-3">
          <Col>
            <Button
              variant="secondary"
              style={{ float: "right" }}
              onClick={handleCloseModal}
            >
              Cerrar
            </Button>
          </Col>
          <Col>
            <Button
              style={{
                backgroundColor: "#5DADE2",
                borderColor: "white",
              }}
              onClick={handleSubmit(onSubmit)}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
