import { faTrash, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import ContactoInterface from "../../../interfaces/ContactoInterface";
import useFetch from "../../../hooks/useFetch";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Unidades } from "../../Formularios/Unidades/Unidades";
import { useGetData } from "../../../hooks/useGetData";
import { ClientesInterface } from "../../../interfaces/ClientesInterface";
import { EstadoInterface } from "../../../interfaces/EstadoInterface";
import { SucursalInterface } from "../../../interfaces/SucursalInterface";
import { AuthContext } from "../../../context/AuthContext";
import { PlataformasxUsuarioInterface } from "../../../interfaces/PlataformasxUsuario";

//estilos
const classes = require("./ServiciosCrear.module.css").default;

let bodyPost = {};

const ServiciosCrear = () => {
  const [contacto, setContacto] = useState<ContactoInterface[]>([]);
  const [actividad, setActividad] = useState<any[]>([]);
  const authContext = useContext(AuthContext);
  const { data: estados } = useGetData("/estado", "estado");
  const { data: sucursales } = useGetData("/sucursal", "sucursal");
  const [estado, setEstado] = useState<number>();
  const [plataforma, setPlataforma] = useState<number>();
  const [sucursal, setSucursal] = useState<number>();
  const [codigo, setCodigo] = useState<string>("");
  const [central, setCentral] = useState<string>("");
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const { data: clientes } = useGetData(
    "/cliente?estatus[eq]=1&includeCentral=true",
    "cliente"
  );

  const [clienteId, setClienteId] = useState<number | undefined>();

  const navigate = useNavigate();

  const [currentContacto, setCurrentContacto] = useState<ContactoInterface>({
    idElement: undefined,
    nombre: "",
    correo: "",
    telefono_movil: "",
    telefono_fijo: "",
  });

  const { sendRequest } = useFetch();

  useEffect(() => {
    // Si solo se tiene una plataforma, se coloca por defecto.
    if (authContext.plataformas.length === 1) {
      setPlataforma(authContext.plataformas[0].id);
      //Si se trata de NMP
      if (authContext.plataformas[0].id === 2) {
        setClienteId(455);
      }
    }
  }, [authContext.plataformas]);

  useEffect(() => {
    if (plataforma === 2) {
      setClienteId(455);
    } else {
      setClienteId(undefined);
    }
  }, [plataforma]);

  //Formulario
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const agregarContactoHandler = () => {
    if (currentContacto.nombre === "") {
      toast.error("Se debe seleccionar el nombre del contacto", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else if (currentContacto.telefono_movil === "") {
      toast.error("Se debe agregar el telefono móvil del contacto", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    setContacto((prev) => [
      ...prev,
      { ...currentContacto, idElement: contacto.length + 1 },
    ]);
    setCurrentContacto({
      idElement: undefined,
      nombre: "",
      correo: "",
      telefono_movil: "",
      telefono_fijo: "",
    });
  };

  //Función que hace el POST para crear el servicio.
  const createServicio = (data: any) => {
    if (!clienteId) {
      toast.error("Se debe seleccionar el cliente", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else if (!estado) {
      toast.error("Se debe seleccionar la localidad para el servicio", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else if (actividad.length === 0) {
      toast.error("Se debe seleccionar una unidad para el servicio", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else if (!plataforma) {
      toast.error("Debe seleccionar una plataforma", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else if (contacto.length === 0) {
      toast.error("Debe agregar al menos un contacto", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else {
      //Desactivar boton de guardar
      buttonRef.current!.disabled = true;
      bodyPost = {
        ...data,
        cliente_id: clienteId,
        ultima_etapa: 1,
        contacto: [...contacto],
        actividad: [...actividad],
        estado_id: estado,
        sucursal_id: sucursal,
        plataforma_id: plataforma,
      };
    }
    sendRequest(
      {
        url: `/servicio`,
        method: "POST",
        body: bodyPost,
      },
      (variable: any) => {
        toast.success("Se guardó el servicio", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate(`/main/servicios/capturados`);
      }
    );
  };

  return (
    <Container className="mb-3">
      <h1>Agregar servicio</h1>
      <h2 className={classes.subtitulo}>Datos generales </h2>
      <Form onSubmit={handleSubmit(createServicio)}>
        <Row className="mb-3">
          <Form.Group as={Col} xs={6}>
            <Form.Label>Folio</Form.Label>
            <Form.Control
              {...register("folio", { maxLength: 50 })}
              placeholder="Ingresar el folio de órden de venta"
            />
            {errors.folio && (
              <Form.Text style={{ color: "red" }}>
                EL folio no puede ser mayor a 50 caracteres.
              </Form.Text>
            )}
          </Form.Group>
          {authContext.plataformas.length > 1 && (
            <Form.Group as={Col}>
              <Form.Label>Plataforma</Form.Label>
              <Select
                placeholder="Selecciona una opción"
                options={authContext.plataformas.map(
                  (plataforma: PlataformasxUsuarioInterface) => {
                    return {
                      value: plataforma.id,
                      label: plataforma.nombre,
                    };
                  }
                )}
                onChange={(plataforma: any) => setPlataforma(plataforma.value)}
              />
            </Form.Group>
          )}
        </Row>

        <h2 className={classes.subtitulo}>Datos del cliente </h2>
        <Row className="mb-3">
          {plataforma !== 2 && (
            <Form.Group as={Col}>
              <Form.Label>Razón social</Form.Label>
              <Select
                onChange={(value: any) => {
                  setClienteId(value?.value);
                  setCodigo(
                    value
                      ? value.extra_data["codigo_sap"]
                        ? value.extra_data["codigo_sap"]
                        : ""
                      : ""
                  );
                  setCentral(
                    value
                      ? value.extra_data["central"]
                        ? value.extra_data["central"]["nombre"]
                        : ""
                      : ""
                  );
                }}
                placeholder="Selecciona una opción"
                isClearable
                isDisabled={!plataforma}
                options={clientes?.map((cliente: ClientesInterface) => {
                  return {
                    value: cliente.id,
                    label: cliente.razon_social,
                    extra_data: cliente,
                  };
                })}
              />
            </Form.Group>
          )}
          {plataforma !== 2 && (
            <Form.Group as={Col}>
              <Form.Label>Nombre comercial</Form.Label>
              <Select
                placeholder="Selecciona una opción"
                isDisabled
                value={clientes
                  ?.filter(
                    (cliente: ClientesInterface) => cliente.id === clienteId
                  )
                  ?.map((cliente: ClientesInterface) => {
                    return {
                      value: cliente.id,
                      label: cliente.nombre_comercial,
                    };
                  })}
              />
            </Form.Group>
          )}
        </Row>
        <Row className="mb-3">
          {plataforma !== 2 && (
            <Form.Group as={Col}>
              <Form.Label>Código SAP</Form.Label>
              <Form.Control
                className="text-center"
                placeholder="Seleccionar un cliente"
                value={codigo}
                disabled
              />
            </Form.Group>
          )}
          {plataforma !== 2 && (
            <Form.Group as={Col}>
              <Form.Label>Central</Form.Label>
              <Form.Control
                className="text-center"
                placeholder="Seleccionar un cliente"
                value={central}
                disabled
              />
            </Form.Group>
          )}
        </Row>
        <Row className="mb-3">
          {clienteId === 455 && (
            <Form.Group as={Col}>
              <Form.Label>Sucursal</Form.Label>
              <Select
                placeholder="Selecciona una opción"
                options={sucursales?.map((sucursal: SucursalInterface) => {
                  return {
                    value: sucursal.id,
                    label: sucursal.nombre,
                    direccion: sucursal.direccion,
                  };
                })}
                onChange={(sucursal: any) => {
                  setSucursal(sucursal.value);
                  setValue("domicilio_cliente", sucursal.direccion);
                }}
              />
            </Form.Group>
          )}
          <Form.Group as={Col}>
            <Form.Label>Domicilio</Form.Label>
            <Form.Control
              {...register("domicilio_cliente", {
                maxLength: 200,
                required: true,
              })}
            />
            {errors.domicilio_cliente && (
              <Form.Text style={{ color: "red" }}>
                El domicilio no puede ser mayor a 200 caracteres y es
                obligatorio.
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Localidad</Form.Label>
            <Select
              placeholder="Selecciona una opción"
              options={estados?.map((estado: EstadoInterface) => {
                return {
                  value: estado.id,
                  label: estado.nombre,
                };
              })}
              required
              onChange={(estado: any) => setEstado(estado.value)}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Latitud</Form.Label>
            <Form.Control
              type="number"
              step={0.00000000001}
              min={12.425848}
              max={33.394759}
              {...register("latitud_cliente", {
                maxLength: 50,
                required: true,
                min: 12.425848,
                max: 33.394759,
              })}
            />
            {errors.latitud_cliente && (
              <Form.Text style={{ color: "red" }}>
                Las coordenadas no puede ser mayor a 50 caracteres.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Longitud</Form.Label>
            <Form.Control
              type="number"
              step={0.00000000001}
              min={-118.959961}
              max={-85.825195}
              {...register("longitud_cliente", {
                maxLength: 50,
                required: true,
                min: -118.959961,
                max: -85.825195,
              })}
            />
            {errors.longitud_cliente && (
              <Form.Text style={{ color: "red" }}>
                Las coordenadas no puede ser mayor a 50 caracteres.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Observaciones</Form.Label>
            <Form.Control
              {...register("notas_cliente", { maxLength: 200 })}
              as="textarea"
            />
            {errors.notas_cliente && (
              <Form.Text style={{ color: "red" }}>
                Las observaciones no pueden ser mayor a 200 caracteres.
              </Form.Text>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Nombre del contacto</Form.Label>
            <Form.Control
              onChange={(event) => {
                setCurrentContacto({
                  ...currentContacto,
                  nombre: event.target.value,
                });
              }}
              value={currentContacto.nombre}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="correo@mail.com"
              onChange={(event) => {
                setCurrentContacto({
                  ...currentContacto,
                  correo: event.target.value,
                });
              }}
              value={currentContacto.correo}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Teléfono móvil</Form.Label>
            <Form.Control
              placeholder="(55) 55555555"
              onChange={(event) => {
                setCurrentContacto({
                  ...currentContacto,
                  telefono_movil: event.target.value,
                });
              }}
              value={currentContacto.telefono_movil}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Teléfono fijo</Form.Label>
            <Form.Control
              placeholder="(55) 55555555"
              onChange={(event) => {
                setCurrentContacto({
                  ...currentContacto,
                  telefono_fijo: event.target.value,
                });
              }}
              value={currentContacto.telefono_fijo}
            />
          </Form.Group>

          <Col xs="auto">
            <Button
              title="Agregar contacto a este servicio"
              style={{
                backgroundColor: "#5DADE2",
                borderColor: "white",
                marginTop: "10px",
              }}
              onClick={agregarContactoHandler}
            >
              <FontAwesomeIcon icon={faUserPlus} />
            </Button>
          </Col>
        </Row>
        {contacto.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>nombre</th>
                <th>correo</th>
                <th>teléfono móvil</th>
                <th>teléfono fijo</th>
                <th>Quitar</th>
              </tr>
            </thead>
            <tbody>
              {contacto.map((cont, index) => (
                <tr key={index}>
                  <td>{cont.idElement}</td>
                  <td>{cont.nombre}</td>
                  <td>{cont.correo}</td>
                  <td>{cont.telefono_movil}</td>
                  <td>{cont.telefono_fijo}</td>
                  <td>
                    <Button
                      style={{
                        backgroundColor: "#5DADE2",
                        borderColor: "white",
                        marginTop: "10px",
                      }}
                      onClick={() =>
                        setContacto((prev) =>
                          prev.filter(
                            (contactoac) =>
                              contactoac.idElement !== cont.idElement
                          )
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <div style={{ marginTop: "15px" }}>
          {clienteId && (
            <Unidades
              setValue={setValue}
              getValues={getValues}
              setActividad={setActividad}
              actividad={actividad}
              idCliente={clienteId}
            />
          )}
          <Button
            ref={buttonRef}
            style={{
              backgroundColor: "#21618C",
              borderColor: "white",
              marginTop: "10px",
            }}
            type="submit"
          >
            Guardar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default ServiciosCrear;
