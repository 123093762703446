import { useCallback, useEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
//interfaces
import { PermisosxRolInterface } from "../../../../interfaces/PermisosxRolInterface";
import SuccessMessage from "../../../Mensajes/SuccessMessage";

//estilos
const classes = require("./RolesAsignar.module.css").default;

const RolesAsignar = () => {
  const { idRol } = useParams();
  const { sendRequest } = useFetch();
  const [permisos, setPermisos] = useState<PermisosxRolInterface[]>();
  const [show, setShow] = useState(false);

  //Función que trata los prueba traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setPermisos(variable.data);
  }, []);

  //Función que trae los permisos por rol
  useEffect(() => {
    sendRequest(
      {
        url: "/permisos_rol",
        method: "POST",
        body: { rol_id: idRol },
      },
      transformData
    );
  }, [sendRequest, transformData, idRol]);

  //Actualizar permisos
  const guardarPermisos = (event: any) => {
    event.preventDefault();
    sendRequest(
      {
        url: "/savepermisos_rol",
        method: "POST",
        body: { rol_id: idRol, permisos: permisos },
      },
      () => {
        setShow(true);
        window.scrollTo(0, 0);
      }
    );
  };

  //Mientras se carga el contenido se muestra un spinner
  if (!permisos) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h1>Lista de permisos para el rol {idRol}</h1>
      {/** Alerta de guardado */}
      {show && (
        <SuccessMessage
          header="Permisos asignados"
          body="Se han asigando los permisos de manera correcta"
          onCloseHandler={() => setShow(false)}
        />
      )}
      <Form onSubmit={guardarPermisos}>
        {permisos.map((permiso, index) => (
          <div key={permiso.id} className="mb-3">
            <Form.Check
              type={"checkbox"}
              id={`default-checkbox`}
              label={permiso.nombre}
              style={{ textAlign: "left" }}
              defaultChecked={permiso.pivot ? true : false}
              onChange={(event) => {
                if (event.target.checked) {
                  permisos[index]["pivot"] = {
                    rol_id: +idRol!,
                    permiso_id: permiso.id,
                  };
                } else {
                  delete permisos[index]["pivot"];
                }
              }}
            />
          </div>
        ))}
        <Button
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
            marginBottom: "20px",
          }}
          type="submit"
        >
          Guardar cambios
        </Button>
      </Form>
    </Container>
  );
};

export default RolesAsignar;
