import { useCallback, useEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
//interfaces
import { PlataformasxUsuarioInterface } from "../../../../interfaces/PlataformasxUsuario";
import SuccessMessage from "../../../Mensajes/SuccessMessage";

//estilos
const classes = require("./UsuarioAsignar.module.css").default;

const UsuarioAsignar = () => {
  const { idUsuario } = useParams();
  const { sendRequest } = useFetch();
  const [plataformas, setPlataformas] =
    useState<PlataformasxUsuarioInterface[]>();
  const [show, setShow] = useState(false);

  //Función que trata los prueba traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setPlataformas(variable.data);
  }, []);

  //Función que trae las plataformas por usuario
  useEffect(() => {
    sendRequest(
      {
        url: "/plataformas_usuario",
        method: "POST",
        body: { usuario_id: idUsuario },
      },
      transformData
    );
  }, [sendRequest, transformData, idUsuario]);

  //Actualizar plataformas
  const guardarPlataformas = (event: any) => {
    event.preventDefault();
    sendRequest(
      {
        url: "/saveplataformas_usuario",
        method: "POST",
        body: { usuario_id: idUsuario, plataformas: plataformas },
      },
      () => {
        setShow(true);
        window.scrollTo(0, 0);
      }
    );
  };

  //Mientras se carga el contenido se muestra un spinner
  if (!plataformas) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h1>Lista de plataformas para el usuario {idUsuario}</h1>
      {/** Alerta de guardado */}
      {show && (
        <SuccessMessage
          header="Plataformas asignadas"
          body="Se han asigando las plataformas de manera correcta"
          onCloseHandler={() => setShow(false)}
        />
      )}
      <Form onSubmit={guardarPlataformas}>
        {plataformas.map((plataforma, index) => (
          <div key={plataforma.id} className="mb-3">
            <Form.Check
              type={"checkbox"}
              id={`default-checkbox`}
              label={plataforma.nombre}
              style={{ textAlign: "left" }}
              defaultChecked={plataforma.pivot ? true : false}
              onChange={(event) => {
                if (event.target.checked) {
                  plataformas[index]["pivot"] = {
                    plataforma_id: plataforma.id,
                    usuario_id: +idUsuario!,
                  };
                } else {
                  delete plataformas[index]["pivot"];
                }
              }}
            />
          </div>
        ))}
        <Button
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
            marginBottom: "20px",
          }}
          type="submit"
        >
          Guardar cambios
        </Button>
      </Form>
    </Container>
  );
};

export default UsuarioAsignar;
