import { Alert } from "react-bootstrap";

type Props = {
  message: string;
  onCloseHandler: () => void;
};

const ServerMessage = ({ onCloseHandler, message }: Props) => {
  return (
    <Alert variant="info" onClose={onCloseHandler} dismissible>
      <Alert.Heading>No se pudo completar la acción</Alert.Heading>
      <p>{message}</p>
    </Alert>
  );
};

export default ServerMessage;
