import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useFetch from "../../../hooks/useFetch";

interface Props {
  actividadId: number;
  showModal: boolean;
  handleCloseModal: () => void;
  refetch: () => void;
}

/**
 * CobroModal Component
 * @description: Permite establecer que un servicio se va a cobrar y el total del mismo.
 * @date 06/03/2023.
 * @param Props actividadId que es el id de la actividad, showModal que es una variable de estado
 * que define si se muestra el modal o no, handleCloseModal es la funcion que cierra el modal.
 * @returns JSX del Modal.
 */

const CobroModal = ({
  showModal,
  handleCloseModal,
  actividadId,
  refetch,
}: Props) => {
  //Formulario
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      equipo: 0,
      servicio: 0,
      traslado: 0,
      observaciones: "",
    },
  });

  const watchAllFields = watch();

  const { sendRequest } = useFetch();

  //Función que hace el POST para crear el usuario.
  const agregarCosto = (data: any) => {
    sendRequest(
      {
        url: `/cobro`,
        method: "POST",
        body: { ...data, actividad_id: actividadId, tipo_cobro_id: 1 },
      },
      (variable: any) => {
        toast.success("Se registró el cobro", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        refetch();
        handleCloseModal();
      }
    );
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Establecer cobro</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(agregarCosto)}>
          <div>
            <Form.Group className="mb-3">
              <Form.Label>Costo de equipo</Form.Label>
              <Form.Control
                type="number"
                step={0.01}
                defaultValue={0.0}
                {...register("equipo", { required: true })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Costo de servicio</Form.Label>
              <Form.Control
                type="number"
                step={0.01}
                defaultValue={0.0}
                {...register("servicio", { required: true })}
              />
              {errors.servicio && (
                <Form.Text style={{ color: "red" }}>
                  El costo del servicio es obligatorio.
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Costo de traslado</Form.Label>
              <Form.Control
                type="number"
                step={0.01}
                defaultValue={0.0}
                {...register("traslado", { required: true })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Total</Form.Label>
              <Form.Control
                type="number"
                disabled
                value={
                  +watchAllFields.equipo +
                  +watchAllFields.servicio +
                  +watchAllFields.traslado
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Observaciones</Form.Label>
              <Form.Control
                {...register("observaciones", { maxLength: 200 })}
                as="textarea"
              />
              {errors.observaciones && (
                <Form.Text style={{ color: "red" }}>
                  Las observaciones no pueden ser mayor a 200 caracteres.
                </Form.Text>
              )}
            </Form.Group>
          </div>
          <Row className="mb-3">
            <Col>
              <Button
                variant="secondary"
                style={{ float: "right" }}
                onClick={handleCloseModal}
              >
                Cerrar
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  backgroundColor: "#21618C",
                  borderColor: "white",
                }}
                type="submit"
              >
                Confirmar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CobroModal;
