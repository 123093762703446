import { useEffect, useState } from "react";
import { useDebouncedValue } from "../../../hooks/useDebouncedValue";
import { FloatingLabel, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

interface Props {
  onDebounce: (value: string) => void;
}

/**
 * Buscar Component
 * @description: Componente que permite buscar con un debouncer.
 * @date 16/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de clientes.
 */
export const Buscar = ({ onDebounce }: Props) => {
  const [textValue, setTextValue] = useState("");
  const debouncedValue = useDebouncedValue(textValue);

  //Se retrasa el analisis del texto.
  useEffect(() => {
    onDebounce(debouncedValue);
  }, [debouncedValue]);

  return (
    <FloatingLabel
      label="Buscar"
      style={{
        position: "relative",
        maxWidth: "100",
      }}
    >
      <Form.Control
        style={{
          display: "inline-block",
        }}
        placeholder="Buscar"
        value={textValue}
        onChange={(event) => {
          setTextValue(event.target.value);
        }}
      />
      <FontAwesomeIcon
        style={{
          position: "absolute",
          padding: "16",
        }}
        size="xl"
        icon={faSearch}
      />
    </FloatingLabel>
  );
};
